import {PreferenceData} from "../../Definitions/Context/PreferencesContextType";
import APIControls from "../../Definitions/API/APIControls";
import SimpleLayoutRequest from "../Requests/SimpleLayoutRequest";

const ToggleSimpleLayoutHandler = (api : APIControls, preferences : PreferenceData, setPreferences : Function) : void => {
    preferences.SimpleLayout = !preferences.SimpleLayout;
    setPreferences({...preferences});
    SimpleLayoutRequest(api);
    sessionStorage.setItem("SimpleLayout", preferences.SimpleLayout.toString());


};
export default ToggleSimpleLayoutHandler;