import React from "react";
import {MarkerTypes, TransitionBuilderType} from "../../../Definitions/Modals/Transitions";
import DefaultTransition from "./Parts/DefaultTransition";
import AppDataType from "../../../Definitions/Context/AppDataType";
import {AxiosResponse} from "axios";
import StatusResponseType from "../../../Definitions/API/StatusResponseType";
import {NotificationTypes} from "../../../Definitions/Modals/Notification";
import {LobbyConnectRequest} from "../../../Systems/Requests/LobbyConnectRequest";
import './Style/ConnectToLobby.css'
import {UserField} from "./Parts/UserInformation";
import LobbyJoinRequest from "../../../Systems/Requests/LobbyJoinRequest";

export default class ConnectToLobby extends DefaultTransition {

    private formData : StatusResponseType|null = null;
    private app : AppDataType;

    constructor(AppData : AppDataType) {
        super(AppData.notifications.Actions.ToggleTransition);
        this.app = AppData;
        this.LoadMarkers([
            // Initial Lobby Connection
            {
                Name: "FormProcess",
                Type: MarkerTypes.Form,
                Animations: [
                    {ElementId: "join-lobby-form", TextChange: "Joining.", Delay: 0},
                    {ElementId: "join-lobby-form", TextChange: "Joining..", Delay: 0},
                    {ElementId: "join-lobby-form", TextChange: "Joining...", Delay: 0},
                ],
                AnimationLoop: true,
                HoldAtMarker: true,
                Actions: {
                    OnStart: (builder: TransitionBuilderType) => {
                        let code = sessionStorage.getItem("lobbyCode")
                        if(code == null) {
                            sessionStorage.clear()
                            return
                        }
                        LobbyConnectRequest(AppData.api, parseInt(code),
                            (result: AxiosResponse<StatusResponseType>): void => {
                                AppData.api.SetToken(result.data.token)
                                this.formData = result.data;

                                if (this.formData.preferences.HideIntro)
                                    builder.MoveTo("JoinLobby")
                                else
                                    builder.MoveTo("StartIntro");
                            },
                            (result) => {
                                console.log(result.message)
                                console.log(result.code)
                                AppData.notifications.Actions.CreateNotification({
                                    AutoHide: true,
                                    Type: NotificationTypes.Error,
                                    Title: "Unable to join lobby",
                                });
                                builder.Stop();
                            });
                    }
                }
            },

            // Short Hello
            {
                Name: "StartIntro",
                Type: MarkerTypes.TextTransition,
                Animations: [
                    {ElementId: "instruction-one-text", TextChange: "Hello!", Delay: 5},
                    {ElementId: "instruction-one-text", TextChange: "In This Game", Delay: 4},
                    {ElementId: "instruction-one-text", TextChange: "Your Friends Might Get You Killed", Delay: 5},
                    {ElementId: "instruction-one-text", TextChange: "Thank You for Playing", Delay: 4},
                    {ElementId: "instruction-one-text", TextChange: "Good Luck!", Delay: 3},
                ],
                Element: WelcomeIntro(),
                HoldAtMarker: true,
                Actions: {
                    OnHold: (builder: TransitionBuilderType) => {
                        // if we already know the user's preferred name/image then skip the intro questions
                        if (this.formData?.member.display_name !== "New User") {
                            // Send lobby join request
                            LobbyJoinRequest(this.app)
                            return
                        }

                        console.log("Going to user form")
                        builder.MoveTo("NewUserData");
                    }
                }
            },

            // Member Info (name/image)
            {
                Name: "NewUserData",

                HoldAtMarker: true,
                Type: MarkerTypes.TextTransition,
                Animations: [
                    {ElementId: "instruction-one-text", TextChange: "You seem new", Delay: 0},
                    {ElementId: "instruction-two-text", TextChange: "tell us who you are", Delay: 0},
                ],
                Element: UserField(AppData),
                Actions: {
                    OnHold: (builder: TransitionBuilderType) => {
                        let field = document.getElementById("new-user-form")
                        if(field == null) return
                        field.classList.remove("hide")
                    }
                }
            }
        ]);
        this.GetBuilder();
    }



    protected EventCallback = () => {
        // All Done
    }

}

function WelcomeIntro() {
    return (
        <React.Fragment key={"instruction-text"}>
            <div className={"w-full h-full absolute bg-black"}>
                <div className={"instruction-title-text"} id={"instruction-one-text"}></div>
                <div className={"instruction-subtitle-text"} id={"instruction-two-text"}></div>
            </div>
        </React.Fragment>
    )
}


