import React from "react";
import APIControls from "../../Definitions/API/APIControls";
import LeaveLobbyRequest from "../Requests/LeaveLobbyRequest";



export default function Logout<T>(event : React.MouseEvent<T>, api : APIControls, GameStateUpdate : Function) {
    LeaveLobbyRequest(api, () => {
        GameStateUpdate(null);
        api.DeleteToken();
    });
}