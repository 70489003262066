import {PreferenceData} from "../../Definitions/Context/PreferencesContextType";
import APIControls from "../../Definitions/API/APIControls";
import HideIntroRequest from "../Requests/HideIntroRequest";

const DisableIntroHandler = (api : APIControls, preferences : PreferenceData, setPreferences : Function) : void => {
    preferences.HideIntro = !preferences.HideIntro;
    setPreferences({...preferences});
    HideIntroRequest(api);
    sessionStorage.setItem("PhasGame_HideIntro", preferences.HideIntro.toString());
}
export default DisableIntroHandler;