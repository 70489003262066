import React from "react";
import {AppDataContext} from "../../../Systems/Context/AppDataContext";
import AppDataType from "../../../Definitions/Context/AppDataType";
import Empty from "../Empty";
import Icon, {IconOptions} from "../Icons";

const SelectOptionsText = () => {
    return (
        <span className={"no-game-option text-gray-200 text-shadow border-b-gray-400 border-b-2"}>Enable A Challenge</span>
    );
}
export default function StartGameForm() {
    const {session} = React.useContext(AppDataContext) as AppDataType;
    if (session.Data == null) return <Empty/>


    if (session.Data.lobby.bounty_one === "off" && session.Data.lobby.bounty_two === "off" && session.Data.lobby.bounty_three === "off")
        return <SelectOptionsText/>

    return (
        <button onClick={session.Actions.StartGame}
            className={" text-green-200 hover:text-green-400 text-shadow border-b-gray-400 border-b-2 flex items-center"}>
            Start Game
            <Icon icon={IconOptions.Play} iconClass={"w-7 h-7 text-green-500"} />
        </button>);
}