import {PreferenceData} from "../../Definitions/Context/PreferencesContextType";
import APIControls from "../../Definitions/API/APIControls";
import MuteRequest from "../Requests/MuteRequest";

const ToggleMuteHandler = (api : APIControls, preferences : PreferenceData, setPreferences : Function)  => {
    preferences.Muted = !preferences.Muted;
    setPreferences({...preferences});
    MuteRequest(api);
    sessionStorage.setItem("PhasGame_Muted", preferences.Muted.toString());
}
export default ToggleMuteHandler;