import React from "react";
import {AppDataContext} from "../../../Systems/Context/AppDataContext";
import AppDataType from "../../../Definitions/Context/AppDataType";
import Empty from "../Empty";


export default function SimpleLayoutToggle() {
    let {preferences} = React.useContext(AppDataContext) as AppDataType;

    if(window.innerWidth < 900) return <Empty/>

    return (
        <button onClick={preferences.Actions.ToggleSimpleLayout} className={"absolute left-4 bottom-4 text-gray-200 hover:text-cyan-200 font-permanent-marker text-shadow"}>
            {preferences.Data.SimpleLayout ? 'Default Layout' : 'Simple Layout'}
        </button>
    )

}