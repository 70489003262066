import React from "react";
import TransitionBuilder, {TransitionState} from "../../Systems/TransitionBuilder";


export enum MarkerTypes {
    TextTransition,
    Form,
    Effect
}

export interface InitialMarkerDataType {
    Name : string,
    Type : MarkerTypes,
    DelayExit?: number,// 5 seconds
    DelayStart?: number,// 0 seconds
    HasCompleted?: boolean, // false
    HasStarted?: boolean, // false
    HoldAtMarker?: boolean, // false
    RemoveOtherMarkers?: boolean, //true
    Element?: React.JSX.Element,
    Body? : React.FunctionComponent<{ children: React.ReactNode }>,
    Animations? : TransitionAnimationType[],
    AnimationLoop? : boolean,
    Actions? : {
        OnStart? : (builder : TransitionBuilderType) => void,
        OnHold? : (builder : TransitionBuilderType) => void,
        OnEnd? : (builder : TransitionBuilderType) => void,
    }
}
export interface MarkerDataType {
    Name : string,
    DelayExit: number,// 5 seconds
    DelayStart: number,// 0 seconds
    HasCompleted: boolean, // false
    HasStarted: boolean, // false
    HoldAtMarker: boolean, // false
    RemoveOtherMarkers: boolean, //true
    Element: React.JSX.Element|null,
    Body: React.FunctionComponent<{ children: React.ReactNode }>,
    Type: MarkerTypes,
    Animations: TransitionAnimationType[],
    AnimationLoop : boolean,
    Actions : {
        OnStart : (builder : TransitionBuilderType) => void,
        OnHold : (builder : TransitionBuilderType) => void,
        OnEnd : (builder : TransitionBuilderType) => void,
    }
}

export interface TransitionAnimationType {
    AddClassList? : string[],
    RemoveClassList? : string[],
    Delay? : number,
    ElementId? : string,
    TextChange? : string,
    WasStarted? : boolean,
}

export interface TransitionBuilderType {
    DOMRender: () => React.JSX.Element,
    AnimationRender: () => void,
    Continue : () => void,
    Stop : () => void,
    MoveTo : (name : string) => void
}

export interface TransitionBuilderParams {
    Body : React.FunctionComponent<{ children: React.ReactNode }>,
    Markers : InitialMarkerDataType[],
    StateToggle : Function,
    Callback : Function
}

export interface BuilderResponseParamsType {
    HasStarted: boolean,
    ShouldHold : boolean,
    HasCompleted : boolean,
    Render: React.JSX.Element[],
    MarkerList: MarkerDataType[]
}

export interface TransitionManagerType {
    GetBuilder: () => TransitionBuilderType,
}

export interface TransitionEventType {
    Fire : () => void
}
