import Notification from "../../Definitions/Modals/Notification";
import {PreferenceData} from "../../Definitions/Context/PreferencesContextType";
import {NotificationDataType} from "../../Definitions/Context/NotificationContextType";


export default function CreateNotificationHandler(notification : Notification,
                                                  currentState: NotificationDataType,
                                                  UpdateState : Function,
                                                  preferences : PreferenceData) {
    currentState.NotificationDetails = notification;
    UpdateState({...currentState});
}