import React from "react";
import LobbyUpdateRequest from "../Requests/LobbyUpdateRequest";
import APIControls from "../../Definitions/API/APIControls";
import {SessionDataType} from "../../Definitions/Context/SessionContextType";

const ChallengeSelectionHandler = (e: React.ChangeEvent<HTMLSelectElement>, api : APIControls, gameData : SessionDataType|null) => {
    if(gameData == null) return;
    let challengeNumber = e.currentTarget.getAttribute("data-challenge-number");
    if (challengeNumber == null) return;
    LobbyUpdateRequest(api, {
        selected_map: gameData.lobby.selected_map,
        bounty_one: challengeNumber === "1" ? e.currentTarget.value : gameData.lobby.bounty_one,
        bounty_two: challengeNumber === "2" ? e.currentTarget.value : gameData.lobby.bounty_two,
        bounty_three: challengeNumber === "3" ? e.currentTarget.value : gameData.lobby.bounty_three
    });


}
export default ChallengeSelectionHandler;