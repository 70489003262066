import React from "react";
import LobbyUpdateRequest from "../Requests/LobbyUpdateRequest";
import APIControls from "../../Definitions/API/APIControls";
import {SessionDataType} from "../../Definitions/Context/SessionContextType";

const MapSelectionHandler = (event: React.MouseEvent<HTMLButtonElement>, api : APIControls, gameData : SessionDataType|null) => {
    if(gameData == null) return;
    if (document.getElementsByClassName('active-button')[0] != null)
        document.getElementsByClassName('active-button')[0].classList.remove("active-button");
    event.currentTarget.classList.add("active-button");
    console.log("handler found");
    let newMap = event.currentTarget.getAttribute("data-map-name");
    if (newMap == null) return;


    console.log("updating map name " + newMap);
    LobbyUpdateRequest(api, {
        selected_map: newMap,
        bounty_one: gameData.lobby.bounty_one,
        bounty_two: gameData.lobby.bounty_two,
        bounty_three: gameData.lobby.bounty_three
    });
}
export default MapSelectionHandler;