import React from "react";
import {
    InitialMarkerDataType,
    MarkerDataType,
    TransitionAnimationType,
    TransitionBuilderParams,
    TransitionBuilderType
} from "../Definitions/Modals/Transitions";
import Empty from "../Content/Components/Empty";

export interface TransitionState {
    MarkerIndex: number;
    AnimationIndex: number
}

export default class TransitionBuilder implements TransitionBuilderType {

    private readonly Body: React.FunctionComponent<{ children: React.ReactNode }>
    private Markers: MarkerDataType[] = [];

    private RenderParts: React.JSX.Element[] = [];
    private StopCalled = false;
    private PendingRender=  false;
    private readonly ToggleStateRender : Function
    private readonly TransitionCompletedCallback: Function;
    private TaskScheduled : boolean = false;
    private timeout : NodeJS.Timeout|null = null;
    private transitionState : TransitionState = {
        MarkerIndex: 0,
        AnimationIndex: 0,
    }

    constructor(initParams: TransitionBuilderParams) {
        this.Body = initParams.Body;
        this.ToggleStateRender = initParams.StateToggle;
        this.transitionState = this.GetState()
        this.UpdateState(this.GetMarkerIndex(), 0)
        this.ProcessMarkers(initParams.Markers);
        this.TransitionCompletedCallback = initParams.Callback;
        this.Loop()
        this.PendingRender = false;
    }

    public GetCurrentMarker = () => this.Markers[this.GetMarkerIndex()];
    public GetCurrentAnimation = (): TransitionAnimationType => this.GetCurrentMarker()?.Animations[this.GetAnimationIndex()];

    public GetMarkerIndex = () => this.transitionState.MarkerIndex;
    public GetAnimationIndex = () => this.transitionState.AnimationIndex;



    public DOMRender = (): React.JSX.Element => {
        let CurrentMarker = this.GetCurrentMarker();
        this.Schedule(2, () => this.Loop())
        this.PendingRender = false
        return (
            <div id={"transition-wrapper"} className={"transition-wrapper absolute top-0 left-0 w-full h-full"}>
                <div id={"transition-wrapper-children absolute top-0 left-0 w-full h-full"}>
                    <CurrentMarker.Body>
                        {this.RenderParts.map((item) => {
                            return item
                        })}
                    </CurrentMarker.Body>
                </div>
            </div>
        )
    }

    public AnimationRender = () => {
        let CurrentAnimation = this.GetCurrentAnimation();
        if (CurrentAnimation === null || this.StopCalled) return;
        if (CurrentAnimation.ElementId === undefined) CurrentAnimation.ElementId = "transition-wrapper-children";

        let element = document.getElementById(CurrentAnimation.ElementId);
        if (element == null) throw new DOMException("Unable to get Element Id " + CurrentAnimation.ElementId);

        if (CurrentAnimation.AddClassList !== undefined) CurrentAnimation.AddClassList.forEach((item) => {
            if (!element?.classList.contains(item)) element?.classList.add(item)
        });
        if (CurrentAnimation.RemoveClassList !== undefined) CurrentAnimation.RemoveClassList.forEach((item) => {
            if (element?.classList.contains(item)) element?.classList.remove(item)
        });
        if (CurrentAnimation.TextChange !== undefined) element.textContent = CurrentAnimation.TextChange
    };

    public Continue = (): void => {
        this.AdvanceMarker();
        this.Loop();
    }

    public Stop = (): void => {
        this.logWithTimestamp("Stop called!");
        this.StopCalled = true;
        this.ClearSchedule()
    }

    public MoveTo = (name : string): void => {
        let thisMarkerQuery = this.Markers.filter(marker => marker.Name === name);
        if (thisMarkerQuery.length === 0) throw new DOMException("Could not find marker with name " + name);
        let thisMarker = thisMarkerQuery[0];
        let markerKey = this.Markers.indexOf(thisMarker);
        this.UpdateState(markerKey, 0)
        this.ClearSchedule()
        this.StartMarker()

        //this.Loop()
    }

    private UpdateState(marker:number = 0, animation : number = 0) {
        this.transitionState = {
            MarkerIndex: marker,
            AnimationIndex: animation,
        }
    }


    private GetState = (): TransitionState   => {
        let markerData = sessionStorage.getItem("TransitionMarkerIndex")
        let animationData = sessionStorage.getItem("TransitionAnimationIndex")
        let returnData: TransitionState = {
            MarkerIndex: parseInt(markerData ?? "0"),
            AnimationIndex: parseInt(animationData ?? "0")
        }
        return returnData
    }

    private  SaveState = () => {
        sessionStorage.setItem("TransitionMarkerIndex", String(this.GetMarkerIndex()))
        sessionStorage.setItem("TransitionAnimationIndex", String(this.GetAnimationIndex()))
    }

    private Loop = (): void => {
        this.TaskScheduled = false
        if(this.timeout != null)
            clearTimeout(this.timeout)

        // Stop Everything
        if (this.StopCalled) {
            this.logWithTimestamp("Stop called. Not Looping");
            return;
        }

        if (this.PendingRender) {
            this.logWithTimestamp("Cancel this run until render is completed");
            this.ClearSchedule()
            return;
        }


        // Handle any Start/End marker delays
        let processDelay = this.HandleMarkerDelays();
        this.logWithTimestamp("Process Delay: " + processDelay);
        if(processDelay) return;

        // Handle holding at marker
        let holdMarker = this.ShouldHoldMarker();
        this.logWithTimestamp("Hold Marker: " + holdMarker);
        if(holdMarker) return;


        // Standard Processing of marker
        this.logWithTimestamp(`Processing current marker & animation`)
        this.Process();
        this.Schedule(1, () => {
            this.TaskScheduled = false
            this.Loop()
        });
        this.logWithTimestamp(`Loop cycle has concluded`);
    }


    private AllAnimationsCompleted = () : boolean => {
        return (this.GetAnimationIndex() === (this.GetCurrentMarker().Animations.length))
    }


    private ClearSchedule = () => {
        if(this.TaskScheduled) {
            this.logWithTimestamp("Removing old scheduled task")
            if(this.timeout == null) return
            clearTimeout(this.timeout);
            this.TaskScheduled = false;
        }
    }

    private Schedule = (diff:number,callback: Function): void => {
        if (this.TaskScheduled) {
            this.logWithTimestamp("Schedule called but a timeout is already set. Ignoring new schedule.");
            return;
        }

        if (this.StopCalled) {
            this.logWithTimestamp("Stop called. Not Looping");
            return;
        }

        if(diff < 1) {
            diff = 0.5
        }

        this.logWithTimestamp(`Scheduling next loop run in ${diff * 1000} ms`);
        this.TaskScheduled = true
        this.timeout = setTimeout(() => {
            this.logWithTimestamp("Calling the scheduled task")
            callback()
            this.TaskScheduled = false;
        }, diff * 1000);
    }

    private Process = (): void => {
        let CurrentMarker = this.GetCurrentMarker();
        if (CurrentMarker === undefined) return this.CompleteTransition();
        let CurrentAnimation = this.GetCurrentAnimation();
        if (!CurrentMarker.HasStarted) {
            this.logWithTimestamp('START MARKER')
            this.StartMarker();
            return
        }
        if (!CurrentAnimation.WasStarted) {
            this.logWithTimestamp('START ANIMATION')
            this.StartAnimation(CurrentAnimation);
            return
        }

        this.logWithTimestamp('ADVANCE ANIMATION')
        this.AdvanceAnimation();
    }

    private HandleMarkerDelays = () : boolean => {
        let currentMarker = this.GetCurrentMarker();
        if (currentMarker.HasStarted && this.AllAnimationsCompleted()) {
            if (currentMarker.DelayExit > 0) {
                this.logWithTimestamp(`Delay end of this marker animation ${currentMarker.DelayExit} seconds`);
                this.Schedule(currentMarker.DelayExit, () => {
                    this.UpdateMarkerData(currentMarker, this.GetMarkerIndex());
                    return this.Loop()
                });
                return true;
            }
            return false;
        }

        if (!currentMarker.HasStarted && currentMarker.DelayStart > 0) {
            this.logWithTimestamp(`Delay start of this marker animation ${currentMarker.DelayStart} seconds`);
            this.Schedule(currentMarker.DelayStart, () => {
                this.UpdateMarkerData(currentMarker, this.GetMarkerIndex());
                return this.Loop()
            });
            return true;
        }


        return false;
    }

    private ShouldHoldMarker = (): boolean => {
        let currentMarker = this.GetCurrentMarker();
        if (!currentMarker.HasCompleted) return false;
        if (currentMarker.HoldAtMarker) currentMarker.Actions.OnHold(this);
        return currentMarker.HoldAtMarker;
    }

    private CompleteTransition = () => {
        this.TransitionCompletedCallback();
    }

    // Start the currentAnimation
    private StartAnimation = (currentAnimation: TransitionAnimationType) => {
        if(this.PendingRender) return;
        this.logWithTimestamp("Starting Transition Marker #" + this.GetMarkerIndex() + " Animation #" + this.GetAnimationIndex());
        let currentMarker =  this.GetCurrentMarker()
        currentMarker.Animations[this.GetAnimationIndex()].WasStarted = true;
        this.UpdateMarkerData(currentMarker, this.GetMarkerIndex());
        this.AnimationRender();
        return;
    }

    private StartMarker = () => {
        if (this.PendingRender) return;
        this.logWithTimestamp("Starting Transition Marker #" + this.GetMarkerIndex());
        let currentMarker = this.GetCurrentMarker();

        if(currentMarker.HasStarted) return
        currentMarker.HasStarted = true;
        this.UpdateMarkerData(currentMarker, this.GetMarkerIndex());
        currentMarker.Actions.OnStart(this);

        if (currentMarker.RemoveOtherMarkers && this.RenderParts.length > 0) this.RenderParts = [];
        if (currentMarker.Element != null) {
            this.logWithTimestamp("Pushing element to renderer!");
            this.ClearSchedule()
            this.RenderParts.push(currentMarker.Element);
            this.UpdateState(this.GetMarkerIndex(), 0);
            this.SaveState()
            this.ToggleStateRender();
        }
    }


    // Intake method to handle marker data and provide default values
    private ProcessMarkers = (markers: InitialMarkerDataType[]) => {
        for (let i = 0; i < markers.length; i++) {
            const marker = markers[i];
            this.Markers.push({
                Name : marker.Name,
                RemoveOtherMarkers: marker.RemoveOtherMarkers ?? true,
                HoldAtMarker: marker.HoldAtMarker ?? false,
                HasStarted: false,
                HasCompleted: false,
                DelayExit: marker.DelayExit ?? 3,
                DelayStart: marker.DelayStart ?? 0,
                Element: marker.Element ?? null,
                Body: marker.Body ?? this.Body,
                Type: marker.Type,
                Animations: marker.Animations ?? [],
                AnimationLoop: marker.AnimationLoop ?? false,
                Actions: {
                    OnStart: () => (marker.Actions?.OnStart !== undefined ? marker.Actions?.OnStart(this) : () => {
                    }),
                    OnEnd: () => (marker.Actions?.OnEnd !== undefined ? marker.Actions?.OnEnd(this) : () => {
                    }),
                    OnHold: () => (marker.Actions?.OnHold !== undefined ? marker.Actions?.OnHold(this) : () => {
                    }),
                }
            });
        }
    }

    // Update current marker in the array
    private UpdateMarkerData = (marker: MarkerDataType, index: number) => {
        if (this.Markers[index] === undefined) {
            this.logWithTimestamp(`Cannot find marker ${index}`)
        }
        this.Markers[index] = marker;
    }


    // All animations in this marker is completed
    private CompleteMarkerAnimations = () => {
        let CurrentMarker = this.GetCurrentMarker();
        CurrentMarker.HasCompleted = true;
        CurrentMarker.Actions.OnEnd(this);
        this.UpdateMarkerData(CurrentMarker, this.GetMarkerIndex());
        if(CurrentMarker.HoldAtMarker) return;
        this.AdvanceMarker();
    }


    // Move on to the next marker
    private AdvanceMarker = () => {
        if (this.Markers.length < this.GetMarkerIndex() + 1) return;
        this.UpdateState(this.GetMarkerIndex() + 1, 0)
    }

    private ResetAnimation = () => {

        // Clean up current animation, so it can run again on loop
        let marker = this.GetCurrentMarker();
        marker.Animations[this.GetAnimationIndex()].WasStarted = false;
        this.UpdateMarkerData(marker, this.GetMarkerIndex());

    }

    // Move on to the next animation
    private AdvanceAnimation = () => {
        let marker = this.GetCurrentMarker();


        // If at the end of current animations
        if (marker.Animations[this.GetAnimationIndex() + 1] === undefined) {
            this.logWithTimestamp("End of Animations Found");
            if (marker.AnimationLoop) {
                this.ResetAnimation()
                this.UpdateState(this.GetMarkerIndex(), 0)
                return;
            }

            this.CompleteMarkerAnimations()
            return;
        }

        this.ResetAnimation() // Reset current animation
        this.UpdateState(this.GetMarkerIndex(), this.GetAnimationIndex() + 1)
        this.logWithTimestamp("Advancing to Marker #" + this.GetMarkerIndex() + " Animation #" + this.GetAnimationIndex());
    }

    private DEBUG : boolean = false
    
    // Utility function to log messages with a timestamp
    logWithTimestamp = (...args: any[]): void => {
        if(!this.DEBUG) return
        const timestamp = new Date().toISOString();
        console.log(`[${timestamp}]`, ...args);
    };

}