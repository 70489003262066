import React from "react";
import "./PlayerEditForm.css"
import {AppDataContext} from "../../../Systems/Context/AppDataContext";
import AppDataType from "../../../Definitions/Context/AppDataType";
import Empty from "../Empty";
import LobbyMember from "../../../Definitions/Modals/LobbyMember";
import {SessionActionType} from "../../../Definitions/Context/SessionContextType";
import Icon, {IconOptions} from "../Icons";


export default function PlayerEditForm() {
    let {session} = React.useContext(AppDataContext) as AppDataType;
    if(session.Data == null) return <Empty />
    if(!session.Data.isEditingProfile) return <Empty />


    return (
        <div id={"player-edit-wrapper"} className={"player-edit-wrapper w-full h-full flex items-center justify-center absolute  font-flower text-xl"}>
            <div className={"w-full h-1/2 flex items-start justify-center z-50"}>
               <FormContent member={session.Data.member} actions={session.Actions} />
            </div>
            <div className={"bg-gray-900 w-full h-full absolute left-0 right-0 opacity-60"}></div>
        </div>
    )
}

function FormContent(variables : { member : LobbyMember, actions : SessionActionType} ) {

    return (
        <div className="justify-center mt-20 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50">
            <div className="relative w-auto mx-auto max-w-4xl">
                <div
                    className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div
                        className="justify-between inline-flex border-b border-solid border-blueGray-200 bg-blue-600 items-center text-gray-100 font-medium font-overpass p-1">
                        Edit Player Information
                        <button type="button"
                                className="text-gray-100 bg-transparent hover:text-gray-900 text-sm ml-auto inline-flex "
                                onClick={variables.actions.HideEditPlayer}>
                            <Icon icon={IconOptions.Times} />
                        </button>
                    </div>
                    <div id={"modal-content"}>
                        <DisplayNameForm defaultValue={variables.member.display_name} update={variables.actions.UpdateDisplayName} />
                        <div className={"w-full"}>
                            <div>
                                <label className="block text-gray-700 text-sm ml-2 font-overpass">Select Your Character</label>
                                <div className={"grid grid-cols-4 gap-2 p-2 items-center align-middle justify-center"}>
                                    <button onClick={variables.actions.UpdateCharacter} data-character-name={"dude-one"}
                                            className={"dude-one edit-player-character border-2 border-gray-800 hover:border-green-300 h-14"}></button>
                                    <button onClick={variables.actions.UpdateCharacter} data-character-name={"dude-two"}
                                            className={"dude-two edit-player-character border-2 border-gray-800 hover:border-green-300 h-14"}></button>
                                    <button onClick={variables.actions.UpdateCharacter} data-character-name={"dude-three"}
                                            className={"dude-three edit-player-character border-2 border-gray-800 hover:border-green-300 h-14"}></button>
                                    <button onClick={variables.actions.UpdateCharacter} data-character-name={"dude-four"}
                                            className={"dude-four edit-player-character border-2 border-gray-800 hover:border-green-300 h-14"}></button>
                                    <button onClick={variables.actions.UpdateCharacter} data-character-name={"women-one"}
                                            className={"women-one edit-player-character border-2 border-gray-800 hover:border-green-300 h-14"}></button>
                                    <button onClick={variables.actions.UpdateCharacter} data-character-name={"women-two"}
                                            className={"women-two edit-player-character border-2 border-gray-800 hover:border-green-300 h-14"}></button>
                                    <button onClick={variables.actions.UpdateCharacter} data-character-name={"women-three"}
                                            className={"women-three edit-player-character border-2 border-gray-800 hover:border-green-300 h-14"}></button>
                                    <button onClick={variables.actions.UpdateCharacter} data-character-name={"women-four"}
                                            className={"women-four edit-player-character border-2 border-gray-800 hover:border-green-300 h-14"}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export function DisplayNameForm(variables : { defaultValue : string, update : (event : React.FormEvent<HTMLFormElement>) => void}) {
    return (
        <form onSubmit={variables.update} className={"p-2 inline-flex"}>
            <input defaultValue={variables.defaultValue} name={"display-name"} placeholder={"Display Name"}
                   className=" bg-gray-100 border-gray-300 text-gray-900 text-sm block focus:bg-white w-full eading-tight p-1"/>
            <button type={"submit"}
                    className={"bg-green-500 w-8 h-full p-1 text-gray-100 hover:bg-green-800 hover:text-gray-300 display-name-update"}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"/>
                </svg>
            </button>
        </form>)
}