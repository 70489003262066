import "./LobbyMapBoard.css"
import React from "react";
import {AppDataContext} from "../../../Systems/Context/AppDataContext";
import AppDataType from "../../../Definitions/Context/AppDataType";
import Empty from "../Empty";
const LobbyMapBoard = () => {
    const {session} = React.useContext(AppDataContext) as AppDataType;
    if(session.Data == null) return <Empty />
    session.Actions.ActivateMapButton(session.Data.lobby.selected_map);
    return (
        <div className={"lobby-bulletin-board drop-shadow text-white font-permanent-marker"}>
            <div className={"text-container grid items-start"}>
                <h2 className={"capitalize no-wrap text-shadow  text-fuchsia-200  text-center"}>Select A Map</h2>
                <button onClick={session.Actions.ChangeMap} data-map-name={"tanglewood"}
                        className={"type-button text-shadow hover:text-purple-200"}>Tanglewood
                    Drive
                </button>
                <button onClick={session.Actions.ChangeMap} data-map-name={"ridgeview"}
                        className={"type-button text-shadow hover:text-red-200 no-wrap"}>Ridgeview
                    Court
                </button>
                <button onClick={session.Actions.ChangeMap} data-map-name={"willow"}
                        className={"type-button text-shadow hover:text-green-200 no-wrap"}>Willow
                    Street
                </button>
                <button onClick={session.Actions.ChangeMap} data-map-name={"edgefield"}
                        className={"type-button text-shadow hover:text-blue-200 no-wrap"}>Edgefield
                    Road
                </button>
                <button onClick={session.Actions.ChangeMap} data-map-name={"bleasdale"}
                        className={"type-button text-shadow hover:text-teal-200 no-wrap"}>Bleasdale
                    Farmhouse
                </button>
                <button onClick={session.Actions.ChangeMap} data-map-name={"grafton"}
                        className={"type-button text-shadow hover:text-orange-200 no-wrap"}>Grafton
                    Farmhouse
                </button>
                <button onClick={session.Actions.ChangeMap} data-map-name={"woodwind"}
                        className={"type-button text-shadow hover:text-pink-200  no-wrap"}>Camp
                    Woodwind
                </button>
                <button onClick={session.Actions.ChangeMap} data-map-name={"maple"}
                        className={"type-button text-shadow hover:text-cyan-200 no-wrap"}>Maple
                    Lodge Campsite
                </button>
                <button onClick={session.Actions.ChangeMap} data-map-name={"sunny_meadows"}
                        className={"type-button text-shadow hover:text-amber-200  no-wrap"}>Sunny
                    Meadows
                </button>
                <button onClick={session.Actions.ChangeMap} data-map-name={"highschool"}
                        className={"type-button text-shadow hover:text-fuchsia-200 no-wrap"}>High
                    School
                </button>
                <button onClick={session.Actions.ChangeMap} data-map-name={"prison"}
                        className={"type-button text-shadow hover:text-gray-200"}>Prison
                </button>
            </div>
        </div>
    )
}
export default LobbyMapBoard;