import React from "react";
import {MapName} from "../Components/DisplayBoards/InGameMapBoard";
import {AppDataContext} from "../../Systems/Context/AppDataContext";
import AppDataType from "../../Definitions/Context/AppDataType";
import Empty from "../Components/Empty";


export function MapSettings() {
    let { session } = React.useContext(AppDataContext) as AppDataType;
    if(session.Data === null) return <Empty/>
     session.Actions.ActivateMapButton(session.Data?.lobby.selected_map)
    return (
        <div className={"simple-layout-map-settings"}>
            <h2 className={"capitalize p-1 font-permanent-marker text-2xl bg-gray-600 text-left"}>
                <div className={"w-full  flex items-center justify-center  text-green-300"}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6 mr-2">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"/>
                    </svg>
                    <MapName map_name={session.Data.lobby.selected_map}/>
                </div>
            </h2>
            <button onClick={session.Actions.ChangeMap} data-map-name={"tanglewood"}
                    className={"w-full bg-emerald-100 hover:text-emerald-200 hover:bg-emerald-600 p-2 grid grid-cols-5"}>
                <div className={"col-span-1 w-full"}></div>
                <div className={"col-span-3 w-full text-left"}>Tanglewood Drive</div>
                <div className={"col-span-1"}></div>
            </button>
            <button onClick={session.Actions.ChangeMap} data-map-name={"ridgeview"}
                    className={"w-full bg-rose-100 hover:text-rose-200 hover:bg-rose-600 p-2 grid grid-cols-5"}>
                <div className={"col-span-1 w-full"}></div>
                <div className={"col-span-3 w-full text-left"}>Ridgeview Court</div>
                <div className={"col-span-1"}></div>
            </button>
            <button onClick={session.Actions.ChangeMap} data-map-name={"willow"}
                    className={"w-full bg-teal-100 hover:text-teal-200 hover:bg-teal-600 p-2 grid grid-cols-5"}>
                <div className={"col-span-1 w-full"}></div>
                <div className={"col-span-3 w-full text-left"}>Willow Street</div>
                <div className={"col-span-1"}></div>
            </button>
            <button onClick={session.Actions.ChangeMap} data-map-name={"edgefield"}
                    className={"w-full bg-pink-100 hover:text-pink-200 hover:bg-pink-600 p-2 grid grid-cols-5"}>
                <div className={"col-span-1 w-full"}></div>
                <div className={"col-span-3 w-full text-left"}>Edgefield Road</div>
                <div className={"col-span-1"}></div>
            </button>
            <button onClick={session.Actions.ChangeMap} data-map-name={"bleasdale"}
                    className={"w-full bg-purple-100 hover:text-purple-200 hover:bg-purple-600 p-2 grid grid-cols-5"}>
                <div className={"col-span-1 w-full"}></div>
                <div className={"col-span-3 w-full text-left"}>Bleasdale Farmhouse</div>
                <div className={"col-span-1"}></div>
            </button>
            <button onClick={session.Actions.ChangeMap} data-map-name={"woodwind"}
                    className={"w-full bg-red-100 hover:text-red-200 hover:bg-red-600 p-2 grid grid-cols-5"}>
                <div className={"col-span-1 w-full"}></div>
                <div className={"col-span-3 w-full text-left"}>Camp Woodwind</div>
                <div className={"col-span-1"}></div>
            </button>
            <button onClick={session.Actions.ChangeMap} data-map-name={"maple"}
                    className={"w-full bg-cyan-100 hover:text-cyan-200 hover:bg-cyan-600 p-2 grid grid-cols-5"}>
                <div className={"col-span-1 w-full"}></div>
                <div className={"col-span-3 w-full text-left"}>Maple Lodge Campsite</div>
                <div className={"col-span-1"}></div>
            </button>
            <button onClick={session.Actions.ChangeMap} data-map-name={"grafton"}
                    className={"w-full bg-purple-100 hover:text-purple-200 hover:bg-purple-600 p-2 grid grid-cols-5"}>
                <div className={"col-span-1 w-full"}></div>
                <div className={"col-span-3 w-full text-left"}>Grafton Farmhouse</div>
                <div className={"col-span-1"}></div>
            </button>
            <button onClick={session.Actions.ChangeMap} data-map-name={"sunny_meadows"}
                    className={"w-full bg-orange-100 hover:text-orange-200 hover:bg-orange-600 p-2 grid grid-cols-5"}>
                <div className={"col-span-1 w-full"}></div>
                <div className={"col-span-3 w-full text-left"}>Sunny Meadows</div>
                <div className={"col-span-1"}></div>
            </button>
            <button onClick={session.Actions.ChangeMap} data-map-name={"highschool"}
                    className={"w-full bg-green-100 hover:text-green-200 hover:bg-green-600 p-2 grid grid-cols-5"}>
                <div className={"col-span-1 w-full"}></div>
                <div className={"col-span-3 w-full text-left"}>High School</div>
                <div className={"col-span-1"}></div>
            </button>
            <button onClick={session.Actions.ChangeMap} data-map-name={"prison"}
                    className={"w-full bg-blue-100 hover:text-blue-200 hover:bg-blue-600 p-2 grid grid-cols-5"}>
                <div className={"col-span-1 w-full"}></div>
                <div className={"col-span-3 w-full text-left"}>Prison</div>
                <div className={"col-span-1"}></div>
            </button>
        </div>
    )
}