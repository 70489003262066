import ContentLoader from "../Content/ContentLoader";
import {AppDataContext, AppDataProvider} from "./Context/AppDataContext";
import WSListener from "./Websocket";
import {NotificationWrapper} from "../Content/Components/Notifications/NotificationWrapper";
import PlayerEditForm from "../Content/Components/Forms/PlayerEditForm";
import TransitionWrapper from "../Content/Components/Transitions/Parts/TransitionWrapper";
import React, {useEffect} from "react";
import AppDataType from "../Definitions/Context/AppDataType";
import Empty from "../Content/Components/Empty";
import StatusRequest from "./Requests/StatusRequest";

export function GameLoader () {
    return (
        <AppDataProvider>
            <TransitionWrapper/>
            <NotificationWrapper/>
            <ContentLoader/>
            <PlayerEditForm/>
            <WSListener/>
            <StateValidator/>
        </AppDataProvider>
    )
}
function StateValidator() {
    let {api, session, notifications, preferences} = React.useContext(AppDataContext) as AppDataType;
    useEffect(() => {
        if (api.HasToken) StatusRequest(api, session.Data, session.Actions.SetSession, preferences.Actions.UpdateAllPreferences);
        setTimeout(() => notifications.Actions.HidePreloader(), 500)
    }, []);

    return <Empty/>
}