import React from "react";
import "./EndGameForm.css"
import {AppDataContext} from "../../../Systems/Context/AppDataContext";
import AppDataType from "../../../Definitions/Context/AppDataType";
import Empty from "../Empty";

export function EndGameForm() {
    const {session} = React.useContext(AppDataContext) as AppDataType;
    if (session.Data == null) return <Empty/>
    return (
        <div className={"in-game-end-sign p-3"}>
            <button onClick={session.Actions.EndGame}
                    className={"font-spray-paint text-shadow text-gray-300 hover:text-red-400"}>
                End Game
            </button>
        </div>);
}