import React from "react";

export default interface PreferencesContextType {
    Data : PreferenceData,
    Actions : PreferenceActions
}
export  interface PreferenceData {
    Muted: boolean,
    HideIntro: boolean,
    SimpleLayout : boolean,
}
export enum BackgroundOptions {
    Lobby = "lobby-background",
    InGame = "in-game-background",
    HomeScreen = "homepage-background",
    SimpleLayout = "simple-layout-background"
}

export interface  PreferenceActions {
    UpdateAllPreferences : (preferences : PreferenceData) => void;
    ToggleMute: (event : React.MouseEvent<HTMLButtonElement>) => void;
    DisableIntro: (event : React.MouseEvent<HTMLButtonElement>) => void;
    ToggleSimpleLayout: (event : React.MouseEvent<HTMLButtonElement>) => void;
    UpdateBackground: () => void;
}