import React, {useEffect} from "react";
import {SessionDataType} from "../../Definitions/Context/SessionContextType";
import {AppDataContext} from "../../Systems/Context/AppDataContext";
import AppDataType from "../../Definitions/Context/AppDataType";
import Empty from "../Components/Empty";

export function BountySettings() {
    let {session} = React.useContext(AppDataContext) as AppDataType;
    if(session.Data === null) return <Empty/>
    return (
        <div>
            <Bounty UpdateBountySetting={session.Actions.UpdateChallengeSetting} sessionData={session.Data} bountyNumber={1}/>
            <Bounty UpdateBountySetting={session.Actions.UpdateChallengeSetting} sessionData={session.Data}  bountyNumber={2}/>
            <Bounty UpdateBountySetting={session.Actions.UpdateChallengeSetting} sessionData={session.Data}  bountyNumber={3}/>
        </div>
    )
}
function Bounty(variables: { UpdateBountySetting : (event : React.ChangeEvent<HTMLSelectElement>)=>void, sessionData : SessionDataType, bountyNumber : number }) {
    let BountyDefault: string = "off";


    let titleClass = "border-cyan-800 bg-cyan-700"
    switch (variables.bountyNumber) {
        case 1:
            BountyDefault = variables.sessionData.lobby.bounty_one
            break;
        case 2:
            BountyDefault = variables.sessionData.lobby.bounty_two
            titleClass = "border-orange-800 bg-orange-700"
            break;
        case 3:
            BountyDefault = variables.sessionData.lobby.bounty_three
            titleClass = "border-yellow-800 bg-yellow-700"
            break;
    }

    return (
        <div className={"w-full"}>
            <h3 className={"w-full p-2 border-b-4  text-gray-200 text-center text-2xl font-creepster " + titleClass}>Bounty
                #{variables.bountyNumber}</h3>
            <div className={"w-full m-auto text-2xl p-4 text-gray-900 bg-white border-b-4 border-gray-200 justify-center flex"}>
                {variables.sessionData.game === null ? (
                        <select id={"bounty-select-" + variables.bountyNumber}
                                data-challenge-number={variables.bountyNumber}
                                onChange={variables.UpdateBountySetting}
                                className={"w-1/2 font-flower capitalize bg-transparent text-center"}
                                defaultValue={BountyDefault}>
                            <option className={"text-gray-900"} value={"off"}>Off</option>
                            <option className={"text-gray-900"} value={"one-game"}>One Game</option>
                            <option className={"text-gray-900"} value={"short"}>Short</option>
                            <option className={"text-gray-900"} value={"medium"}>Medium</option>
                            <option className={"text-gray-900"} value={"long"}>Long</option>
                        </select>) :
                    <div className={"w-full font-flower capitalize bg-transparent text-center"}>
                        {BountyDefault}
                    </div>
                }
            </div>
        </div>
    );
}