import "./PlayerDisplay.css"
import {MuteButton} from "../Notifications/SoundAlert";
import React from "react";
import {AppDataContext} from "../../../Systems/Context/AppDataContext";
import AppDataType from "../../../Definitions/Context/AppDataType";
import Icon, {IconOptions} from "../Icons";
import Empty from "../Empty";
import LobbyMember from "../../../Definitions/Modals/LobbyMember";
import GameDetailsType from "../../../Definitions/API/GameDetailsType";

export const PlayerDisplay = () => {

    return (
        <div className={"grid grid-cols-1 gap-0 ml-5"}>
            <TopDisplay />
            <PlayerStatus playerNumber={1}/>
            <PlayerStatus playerNumber={2}/>
            <PlayerStatus playerNumber={3}/>
            <PlayerStatus playerNumber={4}/>
        </div>
    );
}

function TopDisplay() {
    const {session} = React.useContext(AppDataContext) as AppDataType;
    let className = "lobby";
    if(session.Data == null) return <Empty/>
    if(session.Data.game !== null) className = "in-game";


    return (
        <div className={className + "-top-display top-display w-full h-full z-50 relative"}>
            <div className={"text-container w-full grid grid-cols-1"}>
                <button onClick={session.Actions.Logout}
                        className={"w-full text-white flex items-center text-shadow hover:text-pink-200"}>
                    <Icon icon={IconOptions.Logout} iconClass={"w-6 h-6"}/>
                    Leave Lobby
                </button>
                <button onClick={session.Actions.ShowEditPlayer}
                        className={"w-full text-white flex items-center text-shadow hover:text-cyan-200"}>
                    <Icon icon={IconOptions.EditPlayer} iconClass={"w-6 h-6"}/>
                    Edit Player
                </button>
                <MuteButton iconClass={"w-6 h-6"}/>
            </div>
        </div>
    )
}


export function PlayerStatus(variable: { playerNumber : number}) {
    const {session} = React.useContext(AppDataContext) as AppDataType;
    if (session.Data == null) return <Empty/>
    let thisPlayer = session.Data.members[variable.playerNumber - 1];
    if (thisPlayer == null) return <Empty/>
    let className = "lobby";
    if(session.Data.game !== null) className = "in-game";


    return (
        <div className={className+"-player-sign player-sign w-full z-" + (50 - (variable.playerNumber * 10)) + " relative"}>
            <div className={"text-container w-full flex text-white text-shadow"}>
                <div className={"image-container " + thisPlayer.character_image}></div>
                <div className={"ml-4 text-left"}>
                    <div className={"no-wrap"}>{thisPlayer.display_name}</div>
                    <RenderPlayerStatus player={thisPlayer} />
                </div>
            </div>
        </div>
    );
}

function RenderPlayerStatus(variables : { player : LobbyMember } ) {
    const {session} = React.useContext(AppDataContext) as AppDataType;
    if (session.Data == null || session.Data.game == null) return <Empty/>
    let gameDetails: GameDetailsType = JSON.parse(session.Data.game.details);
    let isDead= gameDetails.MemberStatus[variables.player.id].is_dead ?? false;
    return (
        <div>
            <div className={"inline-flex"}>
                {isDead ?
                    <span className={"text-red-700 stroke-1 stroke-black"}>Dead</span>
                    :
                    <span className={"text-blue-700 stroke-1 stroke-black"}>Alive</span>
                }
            </div>

            {isDead ?
                <button onClick={session.Actions.TogglePlayerDead} data-member-key={variables.player.id}
                        className={"player-action-button bg-transparent hover:text-blue-300 text-white text-shadow font-creepster pl-1 pr-1 ml-2 items-center"}>Revive</button>
                :
                <button onClick={session.Actions.TogglePlayerDead} data-member-key={variables.player.id}
                        className={"player-action-button bg-transparent hover:text-red-300 text-white text-shadow font-creepster pl-1 pr-1 ml-2 items-center"}>Died</button>
            }
        </div>
    )
}