import StatusResponseType from "../../Definitions/API/StatusResponseType";
import {AxiosError, AxiosResponse} from "axios";
import StatusRequest from "./StatusRequest";
import AppDataType from "../../Definitions/Context/AppDataType";


const LobbyJoinRequest = (app : AppDataType) => {

    console.log(app.api.GetToken())
    app.api.Post<StatusResponseType>("/lobby/join", {}, () =>

        // Update the session data since we have joined the lobby.
        StatusRequest(app.api, app.session.Data, app.session.Actions.SetSession, app.preferences.Actions.UpdateAllPreferences),
        // Log something I guess
        (error) => {
        console.log(error)
        console.log(app)
    });
};
export default LobbyJoinRequest;