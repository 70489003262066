import {IconOptions} from "../../Content/Components/Icons";

export default interface Notification {
    Title : string,
    Type : NotificationTypes,
    Message? : string,
    Buttons? : NotificationButtons
    Icon? : IconOptions,
    AutoHide : boolean,
    LightMode? : boolean,
}


export enum NotificationTypes {
    Confirmation,
    Success,
    Error
}

export interface NotificationButtons {
    CancelButtonText? : string
    CancelButtonCallback? : () => void,
    ConfirmButtonText? : string,
    ConfirmButtonCallback? : () => void
    ConfirmButtonType? : ConfirmButtonType

}

export enum ConfirmButtonType {
    Success,
    Warning,
}