import Notification from "../Modals/Notification";
import {TransitionBuilderType} from "../Modals/Transitions";
import AppDataType from "./AppDataType";
export default interface NotificationContextType {
    Data: NotificationDataType,
    Actions: NotificationActionsType
}
export interface NotificationActionsType {
    HideNotification: () => void,
    CreateNotification: (notification: Notification) => void,

    /* Simple Layout */
    UpdatePageTitle: (newTitle: string) => void,
    UpdatePage: (newPage: SimpleLayoutPages) => void,
    ClosePage: () => void,
    HidePreloader: () => void,
    ShowPreloader: () => void,
    StartTransition: (app: AppDataType, transition: Transitions) => void,
    ToggleTransition: () => void
}

export interface NotificationDataType {
    Preloader: boolean,
    SimpleLayoutTitle:string,
    SimpleLayoutPage:SimpleLayoutPages|null,
    TransitionToggleState: boolean,
    TransitionBuilder : TransitionBuilderType|null,
    NotificationDetails: Notification | null
}

export interface TransitionDetails {
    Type : Transitions,
    StateToggle : boolean,
}

export enum Transitions {
    None,
    LobbyJoin,
    GameStart,
    GameSummary
}


export enum SimpleLayoutPages {
    MemberList= "Member List",
    BountySettings = "Bounty Settings",
    MapSettings = "Map Settings",
    Challenges = "Your Bounties"
}