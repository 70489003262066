import ProfileUpdateRequest from "../Requests/ProfileUpdateRequest";
import APIControls from "../../Definitions/API/APIControls";
import ProfileUpdateRequestType from "../../Definitions/API/ProfileUpdateRequestType";
import {NotificationActionsType} from "../../Definitions/Context/NotificationContextType";
import {NotificationTypes} from "../../Definitions/Modals/Notification";
import LobbyMember from "../../Definitions/Modals/LobbyMember";
import React from "react";
export enum ProfileUpdateTypes {
    Character,
    DisplayName
}
export default function ProfileUpdateHandler(event: React.FormEvent<HTMLFormElement>|React.MouseEvent<HTMLButtonElement>, profileType : ProfileUpdateTypes, api : APIControls, notificationActions : NotificationActionsType, member : LobbyMember|undefined, newValue : string|undefined|null) {
    event.preventDefault();
    if (newValue === null || newValue === undefined || member === undefined) return;
    console.log("New value is " + newValue);
    let requestData: ProfileUpdateRequestType = {
        character_image : member.character_image,
        display_name : member.display_name
    }
    switch (profileType) {
        case ProfileUpdateTypes.Character:
            requestData.character_image = newValue
            break;
        case ProfileUpdateTypes.DisplayName:
            requestData.display_name = newValue
            break;
    }

    ProfileUpdateRequest(api, requestData,
        () => {
            notificationActions.CreateNotification({
                Type: NotificationTypes.Success,
                AutoHide: true,
                Title: "Profile successfully updated!",
                LightMode: true
            })
        })
}