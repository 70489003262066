import React from "react";
import "./ChallengeDisplay.css"
import {AppDataContext} from "../../../Systems/Context/AppDataContext";
import AppDataType from "../../../Definitions/Context/AppDataType";
import Empty from "../Empty";
import Challenge from "../../../Definitions/Modals/Challenge";
import {SessionActionType} from "../../../Definitions/Context/SessionContextType";

const ChallengeDisplay = (variables : { challenge_number : number}) => {
    const {session} = React.useContext(AppDataContext) as AppDataType;
    if (variables.challenge_number == null || session.Data == null || session.Data.challenges == null || session.Data.challenges.length === 0) return <Empty/>
    let challengeList = session.Data.challenges.filter(challenge => challenge.member_id === session.Data?.member.id && challenge.bounty_number === variables.challenge_number);
    if (challengeList.length === 0) return <Empty/>
    let thisChallenge = challengeList[0];

    if (session.Data.game == null) return <LobbyDisplay challenge={thisChallenge} actions={session.Actions}/>
    return <InGameDisplay challenge={thisChallenge} actions={session.Actions}/>
}

function LobbyDisplay(variables : { challenge : Challenge, actions : SessionActionType}) {
    let classList = "in-progress";
    if (variables.challenge.was_failed) classList = "fail";
    if (variables.challenge.was_completed) classList = "complete";

    let titleColor = 'text-fuchsia-200';
    if (variables.challenge.bounty_number === 2) titleColor = 'text-cyan-200';
    if (variables.challenge.bounty_number === 3) titleColor = 'text-emerald-200';

    return (

        <div className={"lobby-challenge-sign font-metal " + classList}>
            <h2 className={"text-center mb-1 text-shadow " + titleColor}>Challenge
                #{variables.challenge.bounty_number}</h2>
            {!variables.challenge.was_failed && !variables.challenge.was_completed ?
                <RoundDetails challenge={variables.challenge}/> : ''}
            <p className={"text-gray-100"}>{variables.challenge.phrase}</p>
            <BountyActionButtons challenge={variables.challenge} actions={variables.actions}/>
            <BountyResult was_completed={variables.challenge.was_completed}
                          was_failed={variables.challenge.was_failed}/>
        </div>
    );
}

function InGameDisplay(variables : { challenge : Challenge, actions : SessionActionType} ) {

    let titleColor = 'text-fuchsia-200';
    if (variables.challenge.bounty_number === 2) titleColor = 'text-cyan-200';
    if (variables.challenge.bounty_number === 3) titleColor = 'text-emerald-200';

    return (
        <div className={"in-game-challenge-sign"}>
            <div className={"bounty-poster font-spray-paint text-shadow"}>
                <h2 className={"text-center " + titleColor}>Challenge #{variables.challenge.bounty_number}</h2>
                {!variables.challenge.was_failed && !variables.challenge.was_completed ?
                    <RoundDetails challenge={variables.challenge}/> : ''}
                <p className={"mt-2 " + !variables.challenge.was_failed && !variables.challenge.was_completed ? 'text-gray-200' : 'text-gray-400 mt-2'}>{variables.challenge.phrase}</p>
                <BountyActionButtons challenge={variables.challenge} actions={variables.actions}/>
                <BountyResult was_completed={variables.challenge.was_completed}
                              was_failed={variables.challenge.was_failed}/>
            </div>
        </div>
    );
}


function RoundDetails(variables : { challenge : Challenge}) {
    if (variables.challenge.task.challenge_length === 1)
        return <h4 className={"text-orange-200 text-center"}>One Game Only</h4>

    let roundsLeft =variables.challenge.task.challenge_length -  variables.challenge.games.length;
    if(roundsLeft === 1) return <h4 className={"text-red-200"}>Last Chance!</h4>
    return <h4 className={"text-blue-200 no-wrap"}>{roundsLeft} games remaining</h4>

}

export default ChallengeDisplay;


export function BountyResult(variables: { was_completed : boolean, was_failed: boolean}) {
    if(variables.was_completed)
        return (
            <div className={"challenge-status w-full text-green-400 mt-2 text-shadow justify-center text-center"}>
                Completed!
            </div>);
    if(variables.was_failed)
        return (
            <div className={"challenge-status w-full text-red-400 mt-2 text-shadow justify-center text-center"}>
                Failed!
            </div>);

    return <Empty />
}

export function BountyActionButtons (variables : { challenge : Challenge, actions: SessionActionType}) {
    let ShowIncremental = false;
    let thisBounty = variables.challenge;
    if (thisBounty.task.challenge_type === "incremental"
        && thisBounty.increment_counter < thisBounty.task.counter_goal) ShowIncremental = true;
    if (thisBounty.was_completed || thisBounty.was_failed) return <Empty/>

    return (
        <div className={"mt-1"}>
            <button data-challenge-key={thisBounty.id} onClick={variables.actions.FailChallenge}
                    className={"col-span-1 bg-transparent text-shadow hover:text-red-900 p-2 items-center inline-flex text-red-300 justify-end mr-2"}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-6 h-6 text-red-900">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                </svg>
                Fail Task
            </button>
            {ShowIncremental ?
                <button data-challenge-key={thisBounty.id} onClick={variables.actions.IncrementChallenge}
                        className={"col-span-1 bg-transparent  items-center  text-shadow hover:text-blue-800 p-2  inline-flex  text-blue-300 justify-start"}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6 text-blue-800">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    Increment {thisBounty.increment_counter}/{thisBounty.task.counter_goal}
                </button>
                :
                <button data-challenge-key={thisBounty.id} onClick={variables.actions.CompleteChallenge}
                        className={"col-span-1 bg-transparent  items-center  text-shadow hover:text-green-800 p-2  inline-flex  text-green-300 justify-start"}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6 text-green-900">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5"/>
                    </svg>
                    Complete
                </button>
            }
        </div>
    )
}