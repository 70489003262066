import APIControls from "../../Definitions/API/APIControls";
import StatusResponseType from "../../Definitions/API/StatusResponseType";
import {AxiosError, AxiosResponse} from "axios";
import LobbyUpdateRequestType from "../../Definitions/API/LobbyUpdateRequestType";

const LobbyUpdateRequest = (api : APIControls,
                            request : LobbyUpdateRequestType,
                            successCallback? : (result : AxiosResponse<StatusResponseType>) => void,
                            errorCallback? : (result : AxiosError) => void) => {

    api.Post("/lobby", request, successCallback, errorCallback);
};
export default LobbyUpdateRequest;