import React from "react";
import JoinLobbyHandler from "../Handlers/JoinLobbyRequestHandler";
import APIControls from "../../Definitions/API/APIControls";
import PlayerDiedHandler from "../Handlers/PlayerDiedHandler";
import LogoutHandler from "../Handlers/LogoutHandler";
import {SessionActionType, SessionDataType} from "../../Definitions/Context/SessionContextType";
import {NotificationActionsType} from "../../Definitions/Context/NotificationContextType";
import {IconOptions} from "../../Content/Components/Icons";
import {ConfirmButtonType, NotificationTypes} from "../../Definitions/Modals/Notification";
import EndGameRequest from "../Requests/EndGameRequest";
import ChallengeSelectionHandler from "../Handlers/ChallengeSelectionHandler";
import MapSelectionHandler from "../Handlers/MapSelectionHandler";
import ChallengeUpdateHandler from "../Handlers/ChallengeUpdateHandler";
import {ChallengeOptions} from "../Requests/ChallengeUpdateRequest";
import StartGameRequest from "../Requests/StartGameRequest";
import ProfileUpdateHandler, {ProfileUpdateTypes} from "../Handlers/ProfileUpdateHandler";
import AppDataType from "../../Definitions/Context/AppDataType";

const SessionContext = (GameData : SessionDataType|null, GameStateUpdate : Function, NotificationActions : NotificationActionsType,  api : APIControls) : SessionActionType => {

    return {
        Logout<T>(event: React.MouseEvent<T>): void {
            NotificationActions.ShowPreloader()
            LogoutHandler(event, api, GameStateUpdate)
        },
        JoinLobby: (app : AppDataType, event: React.ChangeEvent<HTMLInputElement>) => JoinLobbyHandler(event, NotificationActions, app),
        HideEditPlayer: () => {
            if(GameData == null || !GameData.isEditingProfile) return;
            let newState  = GameData;
            newState.isEditingProfile = false;
            GameStateUpdate({...newState});
        },
        ShowEditPlayer: () => {
            if(GameData == null || GameData.isEditingProfile) return;
            let newState  = GameData;
            newState.isEditingProfile = true;
            GameStateUpdate({...newState});
        },
        UpdateCharacter: (event : React.MouseEvent<HTMLButtonElement>) => ProfileUpdateHandler(event, ProfileUpdateTypes.Character, api, NotificationActions, GameData?.member, event.currentTarget.getAttribute("data-character-name")),
        UpdateDisplayName: (event : React.FormEvent<HTMLFormElement>) => ProfileUpdateHandler(event, ProfileUpdateTypes.DisplayName, api, NotificationActions, GameData?.member,  new FormData(event.currentTarget).get("display-name")?.toString()),

        TogglePlayerDead: (event: React.MouseEvent<HTMLButtonElement>) => PlayerDiedHandler(event, api, GameData),
        StartGame: (event: React.MouseEvent<HTMLButtonElement>) => NotificationActions.CreateNotification({
            Type: NotificationTypes.Confirmation,
            AutoHide: false,
            Title: "Start A New Game?",
            Buttons: {
                ConfirmButtonText: "Start Now",
                ConfirmButtonType : ConfirmButtonType.Success,
                ConfirmButtonCallback: () => StartGameRequest(api)
            }
        }),
        EndGame: (event: React.MouseEvent<HTMLButtonElement>) => NotificationActions.CreateNotification({
            Type: NotificationTypes.Confirmation,
            AutoHide: false,
            Icon: IconOptions.Stop,
            Title: "End Current Game?",
            Buttons: {
                ConfirmButtonText: "End Now",
                ConfirmButtonType : ConfirmButtonType.Warning,
                ConfirmButtonCallback: () => EndGameRequest(api, GameData?.game?.id ?? 0)
            }
        }),
        FailChallenge: (event: React.MouseEvent<HTMLButtonElement>) => ChallengeUpdateHandler(event, api, ChallengeOptions.FAIL),
        CompleteChallenge: (event: React.MouseEvent<HTMLButtonElement>) => ChallengeUpdateHandler(event, api, ChallengeOptions.COMPLETE),
        IncrementChallenge: (event: React.MouseEvent<HTMLButtonElement>) => ChallengeUpdateHandler(event, api, ChallengeOptions.INCREMENT),
        UpdateChallengeSetting: (event: React.ChangeEvent<HTMLSelectElement>) => ChallengeSelectionHandler(event, api, GameData),
        ChangeMap: (event: React.MouseEvent<HTMLButtonElement>) => MapSelectionHandler(event, api, GameData),
        ActivateMapButton: (selected_map: string) => {
            setTimeout(() => {
                if (document.querySelector("button[data-map-name='" + selected_map + "']") == null) return;
                if (document.getElementsByClassName('active-button')[0] != null)
                    document.getElementsByClassName('active-button')[0].classList.remove("active-button");
                document.querySelector("button[data-map-name='" + selected_map + "']")?.classList.add("active-button");
            }, 1000)
        },
        SetSession: GameStateUpdate
    }
};
export default SessionContext;