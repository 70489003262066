import React from "react";
import {AppDataContext} from "../../../Systems/Context/AppDataContext";
import AppDataType from "../../../Definitions/Context/AppDataType";
import Empty from "../Empty";
import {ConfirmButtonType, NotificationTypes} from "../../../Definitions/Modals/Notification";
import Icons, {IconOptions} from "../Icons";

export default function NotificationContent() {
    const {notifications} = React.useContext(AppDataContext) as AppDataType;
    if(notifications.Data.NotificationDetails == null) return <Empty />
    if (notifications.Data.NotificationDetails.AutoHide) setTimeout(() => notifications.Actions.HideNotification(), 3000);

    let notificationBackground = notifications.Data.NotificationDetails.LightMode === true ? "bg-gray-100" : "bg-gray-900";
    let notificationTextColor  = notifications.Data.NotificationDetails.LightMode === true ? "text-gray-800" : "text-gray-300";
    let thisIcon = <div/>
    switch (notifications.Data.NotificationDetails.Type) {
        case NotificationTypes.Error:
            thisIcon = <Icons icon={notifications.Data.NotificationDetails.Icon ?? IconOptions.Warning}
                              iconClass={"text-orange-500 w-6 h-6"}/>;
            break;
        case NotificationTypes.Confirmation:
            thisIcon = <Icons icon={notifications.Data.NotificationDetails.Icon ?? IconOptions.Question}
                              iconClass={"text-cyan-400 w-6 h-6"}/>;
            break;
        case NotificationTypes.Success:
            thisIcon = <Icons icon={notifications.Data.NotificationDetails.Icon ?? IconOptions.Check}
                              iconClass={"text-green-500 w-6 h-6"}/>;
            break;
    }

    return (
        <div className={"notification w-full max-w-sm " + notificationBackground}>
            <div className={"grid grid-cols-5 gap-0 w-full p-4"}>
                <div className={"col-span-1 flex items-center justify-center"}>
                    {thisIcon}
                </div>
                <div className={"col-span-3 flex items-center justify-start no-wrap capitalize mt-1 " + notificationTextColor}>
                    {notifications.Data.NotificationDetails.Title}
                    <br/>
                    {notifications.Data.NotificationDetails.Message}
                </div>
                <button onClick={notifications.Actions.HideNotification}
                        className={"col-span-1 flex items-center justify-end text-gray-500 hover:text-gray-200"}>
                    <Icons icon={IconOptions.Times}/>
                </button>
            </div>
            <ConfirmButtons/>
        </div>
    )
}

function ConfirmButtons() {
    const {notifications} = React.useContext(AppDataContext) as AppDataType;
    if (notifications.Data.NotificationDetails == null || notifications.Data.NotificationDetails.Type !== NotificationTypes.Confirmation) return <Empty/>
    let details = notifications.Data.NotificationDetails;

    let confirmButtonClass = "bg-blue-400 hover:bg-blue-600";
    switch (details.Buttons?.ConfirmButtonType) {
        case ConfirmButtonType.Warning:
            confirmButtonClass = "bg-rose-400 hover:bg-rose-600";
            break;
        case ConfirmButtonType.Success:
            confirmButtonClass = "bg-emerald-400 hover:bg-emerald-600";
            break;
    }
    const ConfirmCallBack = () => {
        if (details.Buttons == null) return;
        if (details.Buttons.ConfirmButtonCallback !== undefined)
            details.Buttons.ConfirmButtonCallback();
        notifications.Actions.HideNotification();
    };
    return (
        <div className="grid grid-cols-2 gap-0">
            <button
                className={"flex items-center justify-center p-1  text-gray-900 hover:text-gray-200 bg-amber-400 hover:bg-amber-600"}
                onClick={notifications.Actions.HideNotification}>
                <Icons icon={IconOptions.Back} iconClass={"w-6 h-6 mr-2"}/>
                {details.Buttons?.CancelButtonText ?? "Never Mind"}
            </button>
            <button
                className={"flex items-center justify-center p-1 text-gray-900 hover:text-gray-200 " + confirmButtonClass}
                onClick={ConfirmCallBack}>
                {details.Buttons?.ConfirmButtonText ?? "Continue"}
                <Icons icon={IconOptions.Play} iconClass={"w-6 h-6"}/>
            </button>
        </div>
    )
}