'use client'
import {AppDataContext} from "../../../Systems/Context/AppDataContext";
import AppDataType from "../../../Definitions/Context/AppDataType";
import React from "react";
import Empty from "../Empty";
import Icon, {IconOptions} from "../Icons";

export function MuteButton(variables : { iconClass? : string }) {
    const {preferences} = React.useContext(AppDataContext) as AppDataType;

    if (preferences.Data.Muted)
        return (
            <button
                onClick={preferences.Actions.ToggleMute}
                className={"w-full inline-flex items-center text-shadow text-red-200 hover:text-purple-200 ml-1"}>
                <Icon icon={IconOptions.UnMute} iconClass={variables.iconClass ?? 'w-6 h-6'}/>
                {preferences.Data.SimpleLayout ? '' : 'Unmute'}
            </button>)

    return (
        <button onClick={preferences.Actions.ToggleMute}
            className={"w-full inline-flex items-center text-shadow text-white hover:text-red-200 ml-1"}>
            <Icon icon={IconOptions.UnMute} iconClass={variables.iconClass ?? 'w-6 h-6'}/>
            {preferences.Data.SimpleLayout ? '' : 'Mute'}
        </button>)
}

export function NotificationAlert() {
    const {preferences} = React.useContext(AppDataContext) as AppDataType;

    if (preferences.Data.Muted) return <Empty/>
    const audio = new Audio('/notification.wav');
    audio.load();
    audio.volume.toPrecision(10)
    audio.play();
    return <Empty/>
}