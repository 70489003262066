import {MuteButton} from "../Components/Notifications/SoundAlert";
import LobbyMember from "../../Definitions/Modals/LobbyMember";
import {AppDataContext} from "../../Systems/Context/AppDataContext";
import AppDataType from "../../Definitions/Context/AppDataType";
import React from "react";
import Empty from "../Components/Empty";
import Lobby from "../../Definitions/Modals/Lobby";
import SimpleLayoutToggle from "../Components/Forms/SimpleLayoutToggle";

export function Header() {
    let {session, notifications} = React.useContext(AppDataContext) as AppDataType;
    if(session.Data == null) return <Empty />

    return (
        <div className={"w-full bg-gray-700 p-2 text-white items-center grid grid-cols-5 border-gray-800 border-b-4"}>
            <div className={"items-center col-span-1 w-full flex justify-center"}>
                <BackButton HandleBackButton={session.Actions.Logout}/>
            </div>
            <div className={"items-center col-span-3 w-full flex justify-center"}>
                <h3 className={"font-finger-paint text-shadow text-2xl"}>{notifications.Data.SimpleLayoutTitle}</h3>
            </div>
            <div className={"items-center col-span-1 w-full flex justify-end"}>
                {notifications.Data.SimpleLayoutPage != null ? <CloseButton HandleCloseButton={notifications.Actions.ClosePage}/> : null}
            </div>
        </div>
    )
}

export function Footer() {
    let {session} = React.useContext(AppDataContext) as AppDataType;
    if(session.Data == null) return <Empty />


    return (
        <div className={"absolute bottom-0 w-full"}>
            {session.Data.game === null ?
                <StartGame Lobby={session.Data.lobby} HandleStartGame={session.Actions.StartGame} />
                :
                <EndGame HandleEndGame={session.Actions.EndGame}/>
            }
            <div className={"w-full bg-gray-700 p-2 text-white items-center grid grid-cols-5  border-t-4 border-gray-800"}>
                <div className={"col-span-1"}>
                    <SimpleLayoutToggle />
                </div>
                <div className={"col-span-3 w-full flex text-2xl font-flower items-center justify-end pt-2"}>
                    <h3>{session.Data.member.display_name}</h3>
                </div>
                <div className={"items-center col-span-1 w-full flex justify-end"}>
                    <MuteButton />
                    <ProfileArea EditPlayer={session.Actions.ShowEditPlayer} Member={session.Data.member}/>
                </div>
            </div>
        </div>
    )
}

const SelectOptionsText = () => {
    return (
        <div className={"w-full flex justify-center text-xl bg-pink-700 p-2 text-gray-300 border-pink-800 border-t-4"}>
            <h3>Select A Bounty To Begin</h3>
        </div>
    )
}

function StartGame(variables : {Lobby : Lobby, HandleStartGame : (event:React.MouseEvent<HTMLButtonElement>)=>void}) {
    if(variables.Lobby.bounty_one === "off" &&
        variables.Lobby.bounty_two === "off" &&
        variables.Lobby.bounty_three === "off") return <SelectOptionsText/>

        return (
        <button onClick={variables.HandleStartGame}
                className={"w-full flex justify-center text-xl bg-green-700 p-2 text-gray-100 border-t-4 border-green-800"}>
            <h3>Start Game</h3>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor"
                 className={"w-6 h-6 mt-0.5"}>
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"/>
            </svg>
        </button>)
}

function EndGame(variables : {HandleEndGame : (event:React.MouseEvent<HTMLButtonElement>)=>void}) {
    return (
        <button onClick={variables.HandleEndGame}
                className={"w-full flex justify-center text-2xl items-center bg-red-700 text-gray-300 p-2 font-permanent-marker  border-t-4 border-red-800"}>
            <h3>End Game</h3>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg>


        </button>)
}


export function ProfileArea(variables : { EditPlayer : (event:React.MouseEvent<HTMLButtonElement>)=>void, Member : LobbyMember }) {
    return (
        <button onClick={variables.EditPlayer} type="button"
                className="text-white bg-transparent text-sm w-8 h-8 inline-flex items-center col-span-1">
            <div className={"border-2 border-white w-8 h-8 p-2 rounded-full " + variables.Member.character_image}></div>
        </button>
    );
}
export function BackButton(variables : {HandleBackButton : (event:React.MouseEvent<HTMLButtonElement>)=>void}) {
    return (
        <button onClick={variables.HandleBackButton} type="button"
                className="text-white bg-transparent hover:text-blue-200 text-sm w-8 h-8 inline-flex items-center col-span-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"/>
            </svg>
            <span className="sr-only">Go Back</span>
        </button>
    );
}

export function CloseButton(variables : {HandleCloseButton : (event:React.MouseEvent<HTMLButtonElement>)=>void}){
    return (
        <button onClick={variables.HandleCloseButton} type="button"
                className="bg-transparent text-gray-200 hover:text-white text-sm w-8 h-8 inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
            </svg>
            <span className="sr-only">Close modal</span>
        </button>
    )
}
