import {
    NotificationActionsType,
    NotificationDataType, SimpleLayoutPages, TransitionDetails,
    Transitions
} from "../../Definitions/Context/NotificationContextType";
import {PreferenceData} from "../../Definitions/Context/PreferencesContextType";
import CreateNotificationHandler from "../Handlers/CreateNotificationHandler";
import Notification from "../../Definitions/Modals/Notification";
import StartTransitionHandler from "../Handlers/StartTransitionHandler";
import AppDataType from "../../Definitions/Context/AppDataType";

export default function NotificationContext(notifications : NotificationDataType, UpdateState : Function, preferences : PreferenceData) : NotificationActionsType {
    return {
        StartTransition: (app : AppDataType, transition: Transitions) => StartTransitionHandler(app, transition, notifications, UpdateState),
        ToggleTransition: () => {
            let newState = notifications;
            newState.TransitionToggleState = !newState.TransitionToggleState
            UpdateState({...newState})
        },
        CreateNotification: (notification: Notification) => CreateNotificationHandler(notification, notifications, UpdateState, preferences),
        HideNotification: () => {
            let newState = notifications;
            newState.NotificationDetails = null
            let notificationElement = document.getElementById("notification-wrapper");
            if (notificationElement == null) return UpdateState({...newState});
            notificationElement.classList.add("notification-fade-out");
            setTimeout(() => UpdateState({...newState}), 500);
        },
        ShowPreloader: () => {
            let newState = notifications;
            newState.Preloader = true;
            UpdateState({...newState});
        },
        HidePreloader: () => {
            let newState = notifications;
            newState.Preloader = false;
            document.getElementById("preloader-wrapper")?.classList.add("preloader-fade-out");
            setTimeout(() => UpdateState({...newState}), 500);
        },
        UpdatePageTitle: (newTitle: string) => {
            let newState = notifications;
            notifications.SimpleLayoutTitle = newTitle;
            UpdateState({...newState});
            sessionStorage.setItem("SimpleLayoutPageTitle", newTitle)
        },
        ClosePage: () => {
            let newState = notifications;
            notifications.SimpleLayoutPage = null;
            UpdateState({...newState});
            sessionStorage.removeItem("SimpleLayoutPage")
        },
        UpdatePage: (newPage: SimpleLayoutPages) => {
            let newState = notifications;
            notifications.SimpleLayoutPage = newPage;
            UpdateState({...newState});
            sessionStorage.setItem("SimpleLayoutPage", newPage)
        },
    }
}