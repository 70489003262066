import {BountyActionButtons, BountyResult} from "../Components/ActiveChallenges/ChallengeDisplay";
import React from "react";
import Challenge from "../../Definitions/Modals/Challenge";
import {AppDataContext} from "../../Systems/Context/AppDataContext";
import AppDataType from "../../Definitions/Context/AppDataType";
import Empty from "../Components/Empty";
import SessionContextType from "../../Definitions/Context/SessionContextType";


export function Challenges() {
    const {session} = React.useContext(AppDataContext) as AppDataType;
    if (session.Data == null
        || session.Data.challenges == null
        || session.Data.challenges.length === 0
        || session.Data.challenges.filter(task => task.member_id === session.Data?.member.id).length === 0)
        return <NoActiveBounty/>

    return (
        <div className={"overflow-y-scroll pb-4"}>
            <DisplayChallenge session={session} challengeNumber={1}/>
            <DisplayChallenge session={session} challengeNumber={2}/>
            <DisplayChallenge session={session} challengeNumber={3}/>
        </div>
    )
}

function DisplayChallenge(variables : { session : SessionContextType, challengeNumber : number}) {
    if(variables.session.Data === null || variables.session.Data.challenges === null) return <Empty />
    let challengeList = variables.session.Data.challenges.filter(task => task.member_id === variables.session.Data?.member.id  && task.bounty_number === variables.challengeNumber);
    if (challengeList.length === 0) return <Empty/>
    let thisChallenge = challengeList[0];

    let thisBountyTitle = GetTitle(thisChallenge);
    let titleClass = "border-cyan-800 bg-cyan-700"
    let BountyDefault = "";


    switch (variables.challengeNumber) {
        case 1:
            BountyDefault = variables.session.Data.lobby.bounty_one
            break;
        case 2:
            BountyDefault = variables.session.Data.lobby.bounty_two
            titleClass = "border-orange-800 bg-orange-700"
            break;
        case 3:
            BountyDefault = variables.session.Data.lobby.bounty_three
            titleClass = "border-yellow-800 bg-yellow-700"
            break;
    }

    return (
        <div className={"w-full"}>
            <h3 className={"w-full p-2 border-b-4  text-gray-200 text-center text-2xl font-permanent-marker " + titleClass}>{thisBountyTitle}</h3>
            <div className={"w-full m-auto text-2xl p-4 text-gray-900 bg-white justify-center flex font-flower border-b-4 border-gray-200"}>
                {thisChallenge.phrase}
            </div>
            <BountyActionButtons challenge={thisChallenge} actions={variables.session.Actions}/>
            <BountyResult was_completed={thisChallenge.was_completed}
                          was_failed={thisChallenge.was_failed}/>
        </div>
    )

}



function GetTitle (thisBounty : Challenge) {
    let bountyTitle: string = "One Game Only";
    if (thisBounty.task.challenge_length > 1) {
        let OutOf = thisBounty.task.challenge_length;
        let Current = thisBounty.games.length;

        if (Current === OutOf) {
            bountyTitle = "Last Chance!";
        } else {
            bountyTitle = Current + " of " + OutOf;
        }

    }
    return bountyTitle;
}



function NoActiveBounty() {
    return (
        <div className={"w-full p-5 h-1/2  text-center font-flower text-white text-3xl"}>
            No Active Bounty
        </div>);
}
