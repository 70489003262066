import React, {useMemo} from "react";
import {API} from "../API";
import AppDataType from "../../Definitions/Context/AppDataType";
import ContextProps from "../../Definitions/Context/ContextProps";
import PreferencesContext from "./PreferencesContext";
import SessionContext from "./SessionContext";
import NotificationContext from "./NotificationContext";
import {
    NotificationDataType,
    SimpleLayoutPages,
    TransitionDetails,
    Transitions
} from "../../Definitions/Context/NotificationContextType";
import {PreferenceData} from "../../Definitions/Context/PreferencesContextType";
import {SessionDataType} from "../../Definitions/Context/SessionContextType";
import {TransitionState} from "../TransitionBuilder";
export const AppDataContext  = React.createContext<AppDataType|null>(null);

const api = API();

export const AppDataProvider: React.FC<ContextProps> = ({ children}) => {

    const [session, SetSession] = React.useState<SessionDataType | null>(null);
    const [preferences, SetPreferences] = React.useState<PreferenceData>({
        Muted: sessionStorage.getItem("PhasGame_Muted") === "true",
        HideIntro: sessionStorage.getItem("PhasGame_HideIntro") === "true",
        SimpleLayout: window.innerWidth < 900 || sessionStorage.getItem("PhasGame_SimpleLayout") === "true"
    });
    const [notifications, SetNotifications] = React.useState<NotificationDataType>({
        Preloader: true,
        SimpleLayoutPage: GetLastPage(),
        SimpleLayoutTitle: sessionStorage.getItem("SimpleLayoutPageTitle") ?? (session == null || session.game == null) ? "Lobby" : "In Game",
        TransitionBuilder: null,
        TransitionToggleState : false,
        NotificationDetails: null
    });
    const PreferenceActions = useMemo(() => PreferencesContext(session, preferences, SetPreferences, api),[api, preferences, session]);
    const NotificationActions = useMemo(() => NotificationContext(notifications, SetNotifications, preferences),[notifications, preferences]);
    const SessionActions = useMemo(() =>  SessionContext(session, SetSession, NotificationActions, api),[NotificationActions, api, session]);
    let ProviderResponse =  useMemo(function() : AppDataType {
        return {
            api: api,
            session: {
                Data: session,
                Actions: SessionActions
            },
            preferences: {
                Data: preferences,
                Actions: PreferenceActions
            },
            notifications: {
                Data: notifications,
                Actions: NotificationActions
            }
        }
    },[SessionActions, NotificationActions, PreferenceActions, notifications, preferences, session])
    return <AppDataContext.Provider value={ProviderResponse}>{children}</AppDataContext.Provider>;
}

function GetActiveTransition() : TransitionDetails|null {
    let transition = sessionStorage.getItem("transitionOptions");
    if (transition == null) return null;
    let thisTransition = parseInt(transition) as Transitions;
    if (thisTransition === undefined) return null;
    return {
        Type: thisTransition,
        StateToggle: false
    }
}

function GetLastPage() : SimpleLayoutPages|null {
    let page = sessionStorage.getItem("SimpleLayoutPage") ?? null;
    if (page === null) return null;
    switch (page) {
        case "Member List":
            return SimpleLayoutPages.MemberList;
        case "Bounty Settings":
            return SimpleLayoutPages.BountySettings;
        case "Map Settings":
            return SimpleLayoutPages.MapSettings;
        case "Your Challenges":
            return SimpleLayoutPages.Challenges;
        default:
            return null
    }
}