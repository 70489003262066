import ToggleMuteHandler from "../Handlers/ToggleMuteHandler";
import ToggleSimpleLayoutHandler from "../Handlers/ToggleSimpleLayoutHandler";
import DisableIntroHandler from "../Handlers/DisableIntroHandler";
import {PreferenceActions, PreferenceData} from "../../Definitions/Context/PreferencesContextType";
import APIControls from "../../Definitions/API/APIControls";
import BackgroundHandler from "../Handlers/BackgroundHandler";
import {SessionDataType} from "../../Definitions/Context/SessionContextType";


const PreferencesContext = (GameData : SessionDataType|null, preferences : PreferenceData, SetPreferences : Function, api : APIControls) : PreferenceActions => {
    return {
        UpdateAllPreferences: (preferences: PreferenceData) => SetPreferences(preferences),
        DisableIntro: (event) => DisableIntroHandler(api, preferences, SetPreferences),
        ToggleSimpleLayout: (event) => ToggleSimpleLayoutHandler(api, preferences, SetPreferences),
        ToggleMute: (event) => ToggleMuteHandler(api, preferences, SetPreferences),
        UpdateBackground: () => BackgroundHandler(GameData, preferences)
    }
};
export default PreferencesContext;

