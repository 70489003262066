import React from "react";
import {AppDataContext} from "../../../Systems/Context/AppDataContext";
import AppDataType from "../../../Definitions/Context/AppDataType";
import Empty from "../Empty";

export default function ChallengeSettingForm(variables: { bountyNumber : number }) {
    const {session} = React.useContext(AppDataContext) as AppDataType;
    if (session.Data == null) return <Empty/>
    let BountyDefault = "off";
    let classList : string = "";
    switch (variables.bountyNumber) {
        case 1:
            BountyDefault = session.Data.lobby.bounty_one
            classList = "text-cyan-100";
            break;
        case 2:
            BountyDefault =  session.Data.lobby.bounty_two
            classList = "text-orange-100";
            break;
        case 3:
            BountyDefault =  session.Data.lobby.bounty_three
            classList = "text-pink-100";
            break;
    }

    return (
        <div className={"text-shadow font-permanent-marker " + classList}>
            <h2 className={"w-full"}>Challenge #{variables.bountyNumber}</h2>
            <select data-challenge-number={variables.bountyNumber} onChange={session.Actions.UpdateChallengeSetting} className={"bounty-select font-flower text-gray-800 capitalize bg-transparent"} defaultValue={BountyDefault}>
                <option value={"off"}>Off</option>
                <option value={"one-game"}>One Game</option>
                <option value={"short"}>Short</option>
                <option value={"medium"}>Medium</option>
                <option value={"long"}>Long</option>
            </select>
        </div>
    );
}
