import APIControls from "../../Definitions/API/APIControls";
import StatusResponseType from "../../Definitions/API/StatusResponseType";
import {SessionDataType} from "../../Definitions/Context/SessionContextType";
export default function StatusRequest (api : APIControls, currentState : SessionDataType|null, GameStateUpdate : Function, PreferenceStateUpdate : Function) {
    api.Get<StatusResponseType>("/status",
        (response) => {
            let processedData = PrepareGameData(response.data, currentState?.isEditingProfile);
            GameStateUpdate(processedData);
            PreferenceStateUpdate({...response.data.preferences});
        });
}

export function PrepareGameData(newState: StatusResponseType, isEditingProfile? : boolean): SessionDataType | null {
    if (newState == null || newState.lobby == null || newState.member == null) return null;
    return {
        isEditingProfile : isEditingProfile ?? false,
        member: newState.member,
        members: newState.members,
        lobby: newState.lobby,
        game: newState.active_game,
        challenges: newState.challenges
    };
}