import APIControls from "../../Definitions/API/APIControls";

export enum ChallengeOptions {
    FAIL = "fail",
    COMPLETE = "complete",
    INCREMENT = "increment"
}

const Request = (api : APIControls, challenge_id : number, action : ChallengeOptions, successCallback? : ()=>void)=> {
    api.Post("/challenge/"+challenge_id+"/" + action, {}, successCallback);
};
export default Request;