import AppDataType from "../../../../Definitions/Context/AppDataType";
import ProfileUpdateRequest from "../../../../Systems/Requests/ProfileUpdateRequest";
import React from "react";
import Icon, {IconOptions} from "../../Icons";
import LobbyJoinRequest from "../../../../Systems/Requests/LobbyJoinRequest";
import StatusRequest from "../../../../Systems/Requests/StatusRequest";
import Empty, {Break} from "../../Empty";

export const UserField = (app : AppDataType) => {
    let selectedImage : HTMLButtonElement | null = null;

    const SetImage = (name : string) => {
        let photoName = document.getElementById("selected-photo") as HTMLInputElement;
        if(photoName == null) return;
        photoName.value = name
        UpdateData()
    }

    const SetName = (name : string) => {

        let displayName = document.getElementById("display-name") as HTMLInputElement;
        if(displayName == null) return;
        displayName.value = name
        UpdateData()
    }

    const UpdateData = () => {
        let selectedPhoto = document.getElementById("selected-photo") as HTMLInputElement;
        if(selectedPhoto == null || selectedPhoto.value.length === 0) {
            return;
        }
        let displayName = document.getElementById("display-name") as HTMLInputElement;
        if(displayName == null || displayName.value.length === 0) {
            return;
        }

        let name = displayName.value;
        let photo = selectedPhoto.value;
        let submitButton  = document.getElementById("update-user-button");
        if(submitButton == null) return

        if(name.length === 0 || photo.length === 0) {
            submitButton.classList.add("hide")
            return
        }
        submitButton.classList.remove("hide")
        //submitButton.setAttribute("disabled", "disabled")

        ProfileUpdateRequest(app.api, { display_name: name, character_image : photo }, () => {
            if(submitButton == null) return
            submitButton.removeAttribute("disabled");
        })
    }

    const handleImageClick = (event : React.FormEvent<HTMLButtonElement>) => {

        // Remove old class selected from current target
        if(selectedImage != null) {
            selectedImage.classList.remove("selected");
        }

        // Change to new target
        selectedImage = event.currentTarget
        if(selectedImage == null) return // confirm

        // Update visuals and send API update with new information.
        selectedImage.classList.add("selected");
        let name = selectedImage.getAttribute("data-key")
        if(name == null) return
        SetImage(name)

    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let field = event.currentTarget;
        SetName(field.value)

    };
    const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
        console.log("handle submit")
        LobbyJoinRequest(app)
    };

    return (
        <React.Fragment key={"instruction-text"}>
            <div className="w-full h-full absolute bg-black text-white flex flex-col items-center p-5">
                <div className="instruction-title-text" id={"instruction-one-text"}></div>
                <div className="instruction-subtitle-text" id={"instruction-two-text"}></div>
                <div id={"new-user-form"} className={"hide"}>
                    <input
                        type="text"
                        placeholder="Your name?"
                        className="instruction-question"
                        onChange={handleNameChange}
                        maxLength={20}
                    />
                    <div className="instruction-subtitle-text">
                        Select Your Character
                    </div>
                    <div className="photo-slider-container mt-10 pb-2">
                        <div className="photo-slider">
                            {[
                                "dude-one",
                                "dude-two",
                                "dude-three",
                                "dude-four",
                                "women-one",
                                "women-two",
                                "women-three",
                                "women-four",
                            ].map((item, index) => {
                                return (<button onClick={handleImageClick} data-key={item} className={"image-selector"}>
                                    <div className={`polaroid image-container ${item}`}/>
                                </button>)


                            })}
                        </div>
                    </div>
                    <br/>
                    <button onClick={handleSubmit}
                            id={"update-user-button"}
                            className={"inline-flex join-lobby-button hide items-center bg-blue-600 font-permanent-marker text-white hover:text-black pt-2 pb-2 pr-4 pl-4"}>
                        Join
                        <Icon icon={IconOptions.Play} iconClass={'w-8 h-8 ml-1'}/>
                    </button>

                    <input type="hidden" id="selected-photo"/>
                    <input type="hidden" id="display-name"/>
                </div>
            </div>
        </React.Fragment>
    );
}
