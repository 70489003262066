import React from "react";
import TransitionBuilder, {TransitionState} from "../../../../Systems/TransitionBuilder";
import {
    InitialMarkerDataType,
    TransitionBuilderType,
    TransitionManagerType
} from "../../../../Definitions/Modals/Transitions";
export default abstract class DefaultTransition implements TransitionManagerType {

    private Builder: TransitionBuilderType|undefined;
    private Markers: InitialMarkerDataType[]|undefined;
    private Body: React.FC<{ children: React.ReactNode; }>|undefined;
    private readonly ToggleState : Function

    public GetBuilder = () : TransitionBuilderType => this.Builder ?? this.InitializeBuilder();

    protected constructor(stateFunction : Function) {
        this.ToggleState = stateFunction;
    }

    private InitializeBuilder() {
        this.Builder = new TransitionBuilder({
            Body: this.Body ?? this.DefaultBody(),
            StateToggle : this.ToggleState,
            Markers: this.Markers ?? [],
            Callback: () => this.EventCallback()
        });
        return this.Builder;
    }

    protected LoadMarkers = (markers : InitialMarkerDataType[]  ) => this.Markers = markers;

    protected LoadBody = (body : React.FC<{ children: React.ReactNode; }>) => this.Body = body;


    protected abstract EventCallback : () => void;



    private DefaultBody = (): React.FC<{ children: React.ReactNode; }> => ({children}) => {
        return (
            <div className={"transition-wrapper w-full h-full absolute left-0 top-0 grid grid-cols-2 gap-0"}>
                {children}
            </div>
        )
    }
}