import React from "react";
import "./Notification.css"
import NotificationContent from "./NotificationContent";
import {AppDataContext} from "../../../Systems/Context/AppDataContext";
import AppDataType from "../../../Definitions/Context/AppDataType";
import Empty from "../Empty";

export function NotificationWrapper() {
    const {notifications} = React.useContext(AppDataContext) as AppDataType;
    if (notifications.Data.NotificationDetails == null) return <Empty/>
    return (
        <div id={"notification-wrapper"}
             className={"w-full h-full flex items-center justify-center absolute notification-wrapper font-flower text-xl"}>
            <div className={"w-full h-1/2 flex items-start justify-center z-50"}>
                <NotificationContent/>
            </div>
            <div className={"bg-gray-900 w-full h-full absolute left-0 right-0 opacity-60"}></div>
        </div>
    )
}