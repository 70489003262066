import React from "react";
import {PlayerStatus} from "../Components/PlayerDisplay/PlayerDisplay";
import {AppDataContext} from "../../Systems/Context/AppDataContext";
import AppDataType from "../../Definitions/Context/AppDataType";
import Empty from "../Components/Empty";

export function MemberList() {
    let {session} = React.useContext(AppDataContext) as AppDataType;
    if(session.Data === null || session.Data.members.length === 0) return <Empty />

    return (
        <div className={"text-white"}>
            <div className={"w-full simple-layout-members p-2 bg-cyan-700 border-b-4 border-cyan-800 font-flower text-2xl"}>
                <PlayerStatus playerNumber={1}/>
            </div>
            {session.Data.members.length > 1 ?
                <div className={"w-full simple-layout-members p-2 bg-orange-700 border-b-4 border-orange-800  font-flower text-2xl   items-end"}>
                    <PlayerStatus playerNumber={2}/>
                </div> : null}
            {session.Data.members.length > 2 ?
                <div className={"w-full simple-layout-members p-2 bg-emerald-700 border-b-4 border-emerald-800  font-flower text-2xl   items-end"}>
                    <PlayerStatus playerNumber={3}/>
                </div> : null}
            {session.Data.members.length > 3 ?
                <div className={"w-full simple-layout-members p-2 bg-pink-700 border-b-4 border-pink-800  font-flower text-2xl   items-end"}>
                    <PlayerStatus playerNumber={4}/>
                </div> : null}
        </div>

    )
}