import Empty from "../Components/Empty";
import {SimpleLayoutPages} from "../../Definitions/Context/NotificationContextType";
import {BountySettings} from "./BountySettings";
import {MapSettings} from "./MapSettings";
import {Challenges} from "./Challenges";
import {MemberList} from "./MemberList";

export function Section(variables: { UpdatePage : (newPage : SimpleLayoutPages)=>void, CurrentPage : SimpleLayoutPages|null, ThisPage : SimpleLayoutPages}) {
    if (variables.CurrentPage !== variables.ThisPage) return <SectionContentHeader UpdatePage={variables.UpdatePage} CurrentPage={variables.CurrentPage} ThisPage={variables.ThisPage}/>
    return <Empty/>
}

export function SectionContent(variables : { CurrentPage : SimpleLayoutPages|null }) {
    if(variables.CurrentPage == null) return <Empty />
    switch(variables.CurrentPage) {
        case SimpleLayoutPages.BountySettings:
            return <BountySettings/>
        case SimpleLayoutPages.MapSettings:
            return <MapSettings/>
        case SimpleLayoutPages.Challenges:
            return <Challenges/>
        case SimpleLayoutPages.MemberList:
            return <MemberList/>
    }

}

export function SectionContentHeader(variables: { UpdatePage : (newPage : SimpleLayoutPages)=>void, CurrentPage : SimpleLayoutPages|null, ThisPage : SimpleLayoutPages}) {
    if(variables.CurrentPage != null) return <Empty />

    let ThisIcon = <></>;
    let thisClass : string = "";

    const SetPage = () => {
        variables.UpdatePage(variables.ThisPage)
    }

    switch(variables.ThisPage) {
        case SimpleLayoutPages.BountySettings:
            ThisIcon = ( <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                </svg>
            )
            thisClass = "bg-yellow-800 border-yellow-900"
            break;
        case SimpleLayoutPages.MapSettings:
            ThisIcon = ( <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>
            )
            thisClass = "bg-cyan-800 border-cyan-900"
            break;
        case SimpleLayoutPages.Challenges:
            ThisIcon = (  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                               stroke="currentColor" className="w-6 h-6 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"/>
            </svg>)
            thisClass = "bg-orange-800 border-orange-900"
            break;
        case SimpleLayoutPages.MemberList:
            ThisIcon = (  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                               stroke="currentColor" className="w-6 h-6 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"/>
            </svg>)
            thisClass = "bg-purple-800 border-purple-900"
            break;
    }


    return (
        <button onClick={SetPage} className={"w-full p-2 text-white font-finger-paint text-shadow text-xl grid grid-cols-5 border-b-4 " + thisClass}>
            <div className={"col-span-1 items-center flex justify-center"}>
                {ThisIcon}
            </div>
            <div className={"items-center col-span-3 w-full flex text-left text-xl no-wrap"}>
                <h3>{variables.ThisPage}</h3>
            </div>
            <div className={"items-center col-span-1 w-full flex justify-end"}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
            </div>
        </button>
    )
}