import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import { GameLoader } from "./Systems/GameLoader";

export const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <GameLoader />
  </React.StrictMode>
);

export const fadeIn = (field : HTMLElement) : void => {
    field.classList.remove("hide"); // Remove the "hide" class to set the display to block
    setTimeout(() => {
        field.classList.add("show"); // Add the "show" class to trigger opacity transition
    }, 500); // Small delay to ensure CSS transition takes effect
}

export const fadeOut = (field : HTMLElement) : void => {
    field.classList.remove("hide"); // Remove the "hide" class to set the display to block
    setTimeout(() => {
        field.classList.add("show"); // Add the "show" class to trigger opacity transition
    }, 10); // Small delay to ensure CSS transition takes effect
}