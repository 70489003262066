import React from "react";
import {Footer, Header} from "./Components";
import {Section, SectionContent} from "./SectionContent";
import {SimpleLayoutPages} from "../../Definitions/Context/NotificationContextType";
import {AppDataContext} from "../../Systems/Context/AppDataContext";
import AppDataType from "../../Definitions/Context/AppDataType";

export default function SimpleLayoutWrapper() {
    let {notifications} = React.useContext(AppDataContext) as AppDataType;
    return (
        <div className={"w-full h-full overflow-hidden bg-gray-900"}>
            <Header/>
            <div className={"simple-body"}>
                <SectionContent CurrentPage={notifications.Data.SimpleLayoutPage}/>
                <Section UpdatePage={notifications.Actions.UpdatePage}
                         CurrentPage={notifications.Data.SimpleLayoutPage}
                         ThisPage={SimpleLayoutPages.Challenges}/>

                <Section UpdatePage={notifications.Actions.UpdatePage}
                         CurrentPage={notifications.Data.SimpleLayoutPage}
                         ThisPage={SimpleLayoutPages.BountySettings}/>

                <Section UpdatePage={notifications.Actions.UpdatePage}
                         CurrentPage={notifications.Data.SimpleLayoutPage}
                         ThisPage={SimpleLayoutPages.MapSettings}/>

                <Section UpdatePage={notifications.Actions.UpdatePage}
                         CurrentPage={notifications.Data.SimpleLayoutPage}
                         ThisPage={SimpleLayoutPages.MemberList}/>
            </div>
            <Footer/>
        </div>
    )
}