import {NotificationDataType, Transitions} from "../../Definitions/Context/NotificationContextType";
import ConnectToLobby from "../../Content/Components/Transitions/ConnectToLobby";
import AppDataType from "../../Definitions/Context/AppDataType";

export default function StartTransitionHandler(AppData : AppDataType, transition : Transitions, notifications : NotificationDataType, UpdateState : Function) {
    let newState = notifications;
    switch (transition) {
        case Transitions.LobbyJoin:
            newState.TransitionBuilder = new ConnectToLobby(AppData).GetBuilder();
            break;
        case Transitions.GameStart:
            break;
        case Transitions.GameSummary:
            break;
    }
    UpdateState({...newState})
    sessionStorage.setItem("transitionOptions", transition.toString());
}