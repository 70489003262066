import {BackgroundOptions, PreferenceData} from "../../Definitions/Context/PreferencesContextType";
import {SessionDataType} from "../../Definitions/Context/SessionContextType";


export default function BackgroundHandler(GameData : SessionDataType|null, preferences : PreferenceData) {
    if (GameData == null || GameData.member.is_pending) return SetBackground(BackgroundOptions.HomeScreen);
    if (preferences.SimpleLayout) return SetBackground(BackgroundOptions.SimpleLayout);
    if (GameData.game == null) return SetBackground(BackgroundOptions.Lobby);
    SetBackground(BackgroundOptions.InGame);
}


function SetBackground(newBackground : BackgroundOptions) : void {
    console.log(`NEW BACKGROUND: ${newBackground}`);
    if (document.body.classList.contains(newBackground)) return;
    document.body.classList.value = '';
    document.body.classList.add(newBackground);
}