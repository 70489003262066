import React, { useEffect } from "react";
import { AppDataContext } from "../Systems/Context/AppDataContext";
import AppDataType from "../Definitions/Context/AppDataType";
import { PlayerDisplay } from "./Components/PlayerDisplay/PlayerDisplay";
import ChallengeDisplay from "./Components/ActiveChallenges/ChallengeDisplay";
import LobbyMapBoard from "./Components/DisplayBoards/LobbyMapBoard";
import LobbyChallengeBoard from "./Components/DisplayBoards/LobbyChallengeBoard";
import JoinLobbyForm from "./Components/Forms/JoinLobbyForm";
import { EndGameForm } from "./Components/Forms/EndGameForm";
import InGameMapBoard from "./Components/DisplayBoards/InGameMapBoard";
import SimpleLayoutToggle from "./Components/Forms/SimpleLayoutToggle";
import SimpleLayoutWrapper from "./Simple/SimpleLayoutWrapper";

export default function ContentLoader() {
    const { session, preferences } = React.useContext(AppDataContext) as AppDataType;

    useEffect(() => {
        // Update the background each time the preferences or session data change
        preferences.Actions.UpdateBackground();
    }, [session.Data, session.Data?.member.is_pending, preferences.Data.SimpleLayout, preferences.Actions]); // Add dependencies to ensure proper updates

    // Conditional rendering based on session data
    if (session.Data == null || session.Data.member.is_pending) {
        return <JoinLobbyForm />;
    }

    if (preferences.Data.SimpleLayout) {
        return <SimpleLayoutWrapper />;
    }

    return (
        <div className={"w-full h-full grid grid-cols-12 gap-0 relative"}>
            {session.Data.game == null ? <div className={"lobby-metal-challenge-chain"} /> : null}
            <div className={"col-span-3 w-full h-full relative"}>
                <PlayerDisplay />
            </div>
            <div className={"col-span-9 w-full h-full"}>
                <div className={"m-auto relative z-50 h-1/4 grid grid-cols-3 justify-center"}>
                    <ChallengeDisplay challenge_number={1} />
                    <ChallengeDisplay challenge_number={2} />
                    <ChallengeDisplay challenge_number={3} />
                </div>
                {session.Data.game == null ? <Lobby /> : <InGame />}
            </div>
            <SimpleLayoutToggle />
        </div>
    );
}

function InGame() {
    return (
        <div className={"grid grid-cols-3 gap-0 w-full h-3/4 relative items-end"}>
            <div className={"col-span-2"}>
                <InGameMapBoard />
            </div>
            <div className={"col-span-1"}>
                <EndGameForm />
            </div>
        </div>
    );
}

function Lobby() {
    return (
        <div className={"grid grid-cols-2 gap-4 w-full h-3/4 items-end relative"}>
            <div className={"col-span-1"}>
                <LobbyMapBoard />
            </div>
            <div className={"col-span-1"}>
                <LobbyChallengeBoard />
            </div>
        </div>
    );
}
