// API().js
import axios, {AxiosError, AxiosPromise, AxiosResponse} from 'axios';
import APIControls from "../Definitions/API/APIControls";

const TokenVar = "PhasLobbyToken";
export enum CallType {
    GET,
    POST
}


const Controls : APIControls = {
    Get<T>(path: string,
           successCallBack: (response : AxiosResponse<T>) => void,
           errorCallback: (response : AxiosError) => void): void {
        PrepareCall<T>(CallType.GET, path, {}, successCallBack, errorCallback);
    },
    Post<T>(path: string,
            requestData: {} = {},
            successCallBack: (response : AxiosResponse<T>) => void,
            errorCallback: (response : AxiosError) => void): void {
        PrepareCall<T>(CallType.POST, path, requestData, successCallBack, errorCallback);
    },
    DeleteToken: () => sessionStorage.clear(),
    SetToken: (newToken: string) => sessionStorage.setItem(TokenVar, newToken),
    GetToken: () => sessionStorage.getItem(TokenVar),
    HasToken: sessionStorage.getItem(TokenVar) != null,
}

function CreateInstance() {
    let instance = axios.create({
        baseURL: 'https://phasapi.networkglitch.com', // replace with your Laravel server URL
        withCredentials: true,
        headers: {
            'Authorization': 'Bearer ' + Controls.GetToken()
        }
    });
    instance.interceptors.request.use((config: any) => {
        if (config.method === 'post' || config.method === 'put' || config.method === 'delete')
            return UpdateCSRFCookie().then(response => config);
        return config;
    });
    return instance;
}

export const API = () => Controls;

async function UpdateCSRFCookie() {
    return CreateInstance().get("/sanctum/csrf-cookie");
}

const PrepareCall = <ResponseType = any>(call: CallType, path: string, requestData: {} = {}, successCallback: (response : AxiosResponse<ResponseType>) => void, errorCallback: (response : AxiosError) => void) : void  => {
    let promise: AxiosPromise;
    console.log(`API Call To ${path}`)
    switch (call) {
        case CallType.GET:
            promise = CreateInstance().get<ResponseType>(path)
            break;
        case CallType.POST:
            promise = CreateInstance().post<ResponseType>(path, requestData)
            break;
    }
    if (promise == null) return;
    promise.then((result) => {
        if (typeof successCallback == 'function') successCallback(result);
    }).catch((result) => {
        if (typeof errorCallback == 'function') errorCallback(result);
    })
}