import React, {useEffect} from "react";
import "../Style/Transitions.css"
import {AppDataContext} from "../../../../Systems/Context/AppDataContext";
import AppDataType from "../../../../Definitions/Context/AppDataType";
import Empty from "../../Empty";
import {TransitionBuilderType} from "../../../../Definitions/Modals/Transitions";

export default function TransitionWrapper() {

    const {notifications: {Data}} = React.useContext(AppDataContext) as AppDataType;
    let TransitionResponse: TransitionBuilderType | null = Data.TransitionBuilder

    let state = Data.TransitionToggleState
    useEffect(() => {
        if(Data.TransitionToggleState !== state || TransitionResponse == null ) {
            console.log("STATE CHANGE DETECTED!")
        }
    }, [Data.TransitionToggleState, state, TransitionResponse]);

    return (
        <React.Fragment key={`${Data.TransitionToggleState}`}>
            <Preloader show={Data.Preloader}/>
            <Render builder={TransitionResponse} />
        </React.Fragment>
    )
}

function Render(arg : { builder : TransitionBuilderType|null}) {
    if(arg.builder == null)
        return <Empty />
    else
        return arg.builder.DOMRender()
}


export function Preloader(variables : {show : boolean}) {
    if(!variables.show) return <Empty />
    return (
        <div id={"preloader-wrapper"} className={"preloader-wrapper w-full h-full absolute left-0 top-0 bg-gray-900"}>
            <div className={"w-full h-3/4 flex items-center justify-center"}>
                <div>
                    <div className={"text-center flex justify-center items-center"}>
                        <img src={"Infinity.svg"} alt={"infinity-preloader"}/>
                    </div>
                    <div className={"preloader-text text-gray-100 text-6xl font-flower w-full"}>
                        Please Wait.
                    </div>
                </div>
            </div>
        </div>
    )
}
