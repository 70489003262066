import React from "react";
import APIControls from "../../Definitions/API/APIControls";
import PlayerDeadUpdateRequest from "../Requests/PlayerDeadUpdateRequest";
import {SessionDataType} from "../../Definitions/Context/SessionContextType";

const PlayerDiedHandler = (event: React.MouseEvent<HTMLButtonElement>, api : APIControls, GameData : SessionDataType|null ) => {
    if (GameData == null || GameData.game == null) return;
    let member_id = event.currentTarget.getAttribute("data-member-key");
    if (member_id == null) return;
    PlayerDeadUpdateRequest(api, GameData.game.id, parseInt(member_id));
}
export default PlayerDiedHandler;