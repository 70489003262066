import "./LobbyChallengeBoard.css"
import StartGameForm from "../Forms/StartGameForm";
import ChallengeSettingForm from "../Forms/ChallengeSettingForm";
const LobbyChallengeBoard = () => {

    return (
        <div className={"lobby-challenge-board drop-shadow text-white font-permanent-marker"}>
            <div className={"text-container grid justify-center"}>
                <div className={"start-game"}>
                    <StartGameForm/>
                </div>
                <ChallengeSettingForm bountyNumber={1}/>
                <ChallengeSettingForm bountyNumber={2}/>
                <ChallengeSettingForm bountyNumber={3}/>
            </div>
        </div>
    )
}

export default LobbyChallengeBoard;