import "./JoinLobbyForm.css"
import React from "react";
import AppDataType from "../../../Definitions/Context/AppDataType";
import {AppDataContext} from "../../../Systems/Context/AppDataContext";

export default function JoinLobbyForm() {
    const {session, notifications, api, preferences} = React.useContext(AppDataContext) as AppDataType;
    const AppData = React.useContext(AppDataContext) as AppDataType;

    return (
        <form className={"w-full h-full flex items-end"}>
            <div className={"wood-sign"}>
                {notifications.Data.TransitionBuilder == null ? <Form ChangeAction={ (e) => {
                        session.Actions.JoinLobby(AppData, e)
                    }}/> :
                    <div id={"join-lobby-form"}
                         className={"text-container font-finger-paint text-center text-gray-100 text-shadow grid grid-cols-1 content-center"}>Joining</div>}
            </div>
        </form>
    )
}

function Form( variables : {ChangeAction : (e : React.ChangeEvent<HTMLInputElement>) => void}) {
    return (
        <div id={"join-lobby-form"}
             className={"text-container font-finger-paint text-center text-gray-100 text-shadow grid grid-cols-1 content-center"}>

            <div className={"w-full"}> Enter Lobby Code     </div>
            <input type={"hidden"} id={"lobby-code-value"} />
            <input autoFocus={true} autoComplete={"off"}
                   className={"w-full text-center font-finger-paint bg-transparent focus:outline-0 text-shadow"}
                   onChange={variables.ChangeAction}
                   type={"password"}
                    id={"lobby-code-input"}
                   placeholder={"000000"}
                   pattern={"[0-9]{6}"}
                   maxLength={6}
            />
        </div>
    )
}