import React from "react";
import {NotificationActionsType, Transitions} from "../../Definitions/Context/NotificationContextType";
import AppDataType from "../../Definitions/Context/AppDataType";
const JoinLobbyHandler = (e: React.ChangeEvent<HTMLInputElement>, {StartTransition}: NotificationActionsType, app : AppDataType ): void => {
    e.preventDefault();
    let lobbyCodeValue = e.currentTarget.value;
    if (lobbyCodeValue.length < 6) return;
    sessionStorage.setItem("lobbyCode", lobbyCodeValue.toString());
    StartTransition(app, Transitions.LobbyJoin)
};
export default JoinLobbyHandler;
