import Empty from "../Empty";
import React from "react";
import "./InGameMapBoard.css"
import {AppDataContext} from "../../../Systems/Context/AppDataContext";
import AppDataType from "../../../Definitions/Context/AppDataType";

export default function InGameMapBoard() {
    const {session} = React.useContext(AppDataContext) as AppDataType;
    if (session.Data == null) return <Empty/>

    return (
        <div className={"in-game-map-board"}>
            <div className={"text-container"}>
                <MapName map_name={session.Data.lobby.selected_map}
                         classList={" text-gray-200 text-2xl font-spray-paint text-shadow"}/>
                <div className={"map-container " + session.Data.lobby.selected_map}></div>
            </div>
        </div>
    )
}


export function MapName(variables : { map_name: string, classList? : string}) {
    let properName :string;
    switch(variables.map_name) {
        default:
            properName = "6 Tanglewood Drive";
            break;
        case 'ridgeview':
            properName = "10 Ridgeview Court";
            break;
        case 'willow':
            properName = "13 Willow Street";
            break;
        case 'edgefield':
            properName = "42 Edgefield Road.";
            break;
        case 'bleasdale':
            properName = "Bleasdale Farmhouse";
            break;
        case 'woodwind':
            properName = "Camp Woodwind";
            break;
        case 'maple':
            properName = "Maple Lodge Campsite";
            break;
        case 'grafton':
            properName = "Grafton Farmhouse";
            break;
        case 'sunny_meadows':
            properName = "Sunny Meadows";
            break;
        case 'highschool':
            properName = "High School";
            break;
        case 'prison':
            properName = "Prison";
            break;


    }

    return (
        <h2 id={"map-text-name"} className={variables.classList}>{properName}</h2>
    )
}

